import React from 'react'
import { PlanFragment } from 'src/graphql/generated/graphql'
import { usePlansSubscriptions, useSelector, useState } from 'src/hooks'
import { PlanIdType } from 'src/pricing_page/components/pricingTile/PlanTiles'
import {
  useCheckout,
  useDeleteCheckoutLines,
  usePlans,
  useProductVariant,
} from 'src/react_query/queries/hooks'
import { getOrderFromDescription } from '../helper'
import { usePlanFeatures } from 'src/pricing_page/hooks/usePlanIncludes'
import { usePlanManagement } from 'src/saleor/utils/usePlanManagement'

import DynamicSubscriptionSelectionTile from './DynamicSubscriptionSelectionTile'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { getPlanDescriptions } from 'src/saleor/utils/getPlanDescriptions'
import { Checkout } from 'src/saleor_graphql/generated/graphql'
import { checkIsInCart } from 'src/pricing_page/helpers/helpers'
import BasicToaster from './BasicToaster'
import BasicTransition from './BasicTransition'
import { DialogUpsaleCheckout } from 'src/upsale/components/checkout/DialogUpsaleCheckout'
import { SubscriptionCheckout } from 'src/saleor/components/subscription_checkout/SubscriptionCheckout'
import UnlockButton from './UnlockButton'

type DynamicSubscriptionSelectionProps = {
  plansArray: PlanIdType[]
  wrapperStyle?: React.CSSProperties
  shouldHideCurrentPlan?: boolean
  onChange?: (plan: PlanFragment) => void
}

const DynamicSubscriptionSelection: React.FC<
  DynamicSubscriptionSelectionProps
> = ({ plansArray, wrapperStyle, shouldHideCurrentPlan = false, onChange }) => {
  const {
    user: { account },
  } = useSelector(state => state)
  const currentPlan = account?.plan
  const isMobile = useSelector(state => state.window.width <= 520)

  const [stagedPlan, setStagedPlan] = useState<PlanFragment | undefined>(
    shouldHideCurrentPlan ? undefined : currentPlan,
  )

  const {
    addToCart,
    hasCheckoutError,
    checkoutErrorMessage,
    isCreatingCheckout,
    isUpdatingCheckout,
  } = useHandleProductActions(stagedPlan?.stripeId)

  const { planDescriptions } = usePlanManagement([
    { id: stagedPlan?.id ?? plansArray[0].id },
  ])

  const features = usePlanFeatures(planDescriptions[stagedPlan?.id ?? '1'])

  const plansQuery = usePlans({ suspense: true })
  const { newPlan } = usePlansSubscriptions()
  const { mutate: deleteCheckoutLines } = useDeleteCheckoutLines()
  const plans = plansQuery.data!

  const plansToDisplay = plansArray.map(id =>
    plans?.find((plan: PlanFragment) => id.id === plan.id && plan.id !== '1'),
  )

  const { data } = useProductVariant({ sku: stagedPlan?.stripeId ?? '' })
  const saleorPlanDescriptions = getPlanDescriptions(plans)

  const [selectedSku, setSelectedSku] = useState(stagedPlan?.stripeId ?? '')
  const variantIdOnPlan = stagedPlan
    ? saleorPlanDescriptions[stagedPlan?.id]?.saleorVariantId
    : ''
  const variantId = variantIdOnPlan ?? data?.id
  const [selectedOptionVariantId, setSelectedOptionVariantId] = useState<
    string | undefined
  >(stagedPlan?.relatedPackage?.saleorProductId ?? variantId)

  const { data: checkout } = useCheckout()

  const isDowngraded =
    newPlan &&
    currentPlan &&
    (getOrderFromDescription(newPlan.description) ?? 0) <
      (getOrderFromDescription(currentPlan.description) ?? 0)

  const handleOnPlanChange = (plan: PlanFragment) => {
    setStagedPlan(plan)
    setSelectedSku(plan.stripeId)
    setSelectedOptionVariantId(
      saleorPlanDescriptions[plan?.id]?.saleorVariantId,
    )
    onChange?.(plan)
  }

  const handleStageSelectedOption = (optionId: string, sku?: string | null) => {
    setSelectedOptionVariantId(optionId)
    if (!sku) return
    setSelectedSku(sku)
  }

  const [selPlan, setSelPlan] = useState<PlanFragment | undefined>(undefined)
  const [isCheckoutOpen, setIsCheckoutOpen] = useState<boolean>(false)
  const handleUnlock = () => {
    const isOnCart = checkIsInCart(checkout, selectedSku)
    if (isOnCart || currentPlan?.id === stagedPlan?.id) return

    const hasNonBasicPlan = currentPlan && currentPlan.id !== '1'
    if (hasNonBasicPlan && stagedPlan?.stripeId === selectedSku) {
      setSelPlan(stagedPlan)
    } else {
      addToCart(selectedOptionVariantId)
      setIsCheckoutOpen(true)
    }
  }

  const handleCloseCheckout = (shouldKeepCheckoutOpen: boolean) => {
    if (!shouldKeepCheckoutOpen && checkout) {
      deleteCheckoutLines({ linesIds: checkout?.lines.map(line => line.id) })
    }
    setIsCheckoutOpen(shouldKeepCheckoutOpen)
  }

  // No loader for now as some hooks loads some legacy queries that blocks the flow
  return (
    <div style={wrapperStyle}>
      {plansToDisplay &&
        plansToDisplay.map(plan => {
          if (!plan?.description || plan.id === currentPlan?.id) return null
          const isSelected = plan.id === stagedPlan?.id
          return (
            <DynamicSubscriptionSelectionTile
              plan={plan}
              isDowngraded={!!isDowngraded}
              isSelected={isSelected}
              onChange={handleOnPlanChange}
              features={isSelected ? features : undefined}
              variantIdOnPlan={variantIdOnPlan}
              selectedOptionVariantId={selectedOptionVariantId}
              handleStageSelectedOption={handleStageSelectedOption}
              checkout={checkout as Checkout}
            />
          )
        })}
      <div
        style={{
          paddingTop: '1rem',
          position: isMobile ? 'absolute' : undefined,
          bottom: '18px',
          width: isMobile ? 'calc(100dvw - 80px)' : undefined,
        }}
      >
        <UnlockButton
          plan={stagedPlan}
          selectedSku={selectedSku}
          handleClick={handleUnlock}
          isDisabled={
            checkIsInCart(checkout, selectedSku) ||
            currentPlan?.id === stagedPlan?.id
          }
        />
      </div>
      {hasCheckoutError && checkoutErrorMessage && (
        <BasicToaster message={checkoutErrorMessage} />
      )}
      <BasicTransition
        shouldDisplay={isUpdatingCheckout || isCreatingCheckout}
        message="Creating checkout..."
      />
      {selPlan && (
        <DialogUpsaleCheckout
          selectedItem={selPlan}
          onClose={() => {
            setSelPlan(undefined)
            handleCloseCheckout(false)
          }}
        />
      )}
      <SubscriptionCheckout
        isOpen={isCheckoutOpen}
        setIsOpen={handleCloseCheckout}
      />
    </div>
  )
}

export default DynamicSubscriptionSelection
