import React from 'react'
import { ConfirmDialog } from '@sendoutcards/quantum-design-ui'
import { useActions, useQueryParams } from 'src/hooks'
import { useMarketingContent } from 'src/react_query'
import { browserHistory } from 'src/redux/browserHistory'

export const ClaimRewardDialog = (props: {
  isSuccess: boolean
  redirectURI?: string
  onClose: () => void
}) => {
  const { isSuccess, redirectURI, onClose } = props
  const actions = useActions()
  const { removeQueryParams } = useQueryParams()
  const { data, isLoading } = useMarketingContent()
  if (isLoading || !data) return <></>
  const claimRewardSuccesTitle = data.claimRewardSuccessTitle.content
  const claimRewardSuccessContent = data.claimRewardSuccessContent.content
  const claimRewardFailTitle = data.claimRewardFailTitle.content
  const claimRewardFailContent = data.claimRewardFailContent.content

  const handleOnDefault = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('rewardCode')
    searchParams.delete('redirect_uri')
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    browserHistory.push(newUrl)
    removeQueryParams()
    actions.openAccount()
  }

  return (
    <ConfirmDialog
      isOpen={true}
      title={isSuccess ? claimRewardSuccesTitle : claimRewardFailTitle}
      description={
        isSuccess ? claimRewardSuccessContent : claimRewardFailContent
      }
      accept={{
        title: 'Close',
        onClick: () => {
          if (redirectURI) {
            try {
              const decodedURI = decodeURIComponent(redirectURI)
              window.open(decodedURI, '_self')
            } catch (error) {
              handleOnDefault()
            }
          } else {
            handleOnDefault()
          }
          onClose()
        },
      }}
    />
  )
}
