import {
  Anchor,
  ConfirmDialog,
  Div,
  Flex,
  Scrollable,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Token } from '@stripe/stripe-js'

import React, { useRef } from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { Button } from 'src/design_system/components/Button/Button'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { AccountInput } from 'src/graphql/generated/graphql'
import { noop } from 'src/helpers/appHelpers'
import { useActions, useSelector, useState } from 'src/hooks'
import { ExpenseBucket, ExpenseBucketCard } from 'src/payments/components'
import CreditCard from 'src/payments/components/CreditCard/CreditCard'
import CreditCardForm from 'src/payments/components/CreditCardForm/CreditCardForm'
import {
  useAccountQuery,
  useCreditCardManagerMarketingContent,
  useUpdateAccount,
} from 'src/react_query'
import { parseError } from 'src/utils/parseError'

type PaymentSettingsCardProps = {
  formBackgroundOverride?: string
}

export const PaymentSettingsCard = (props: PaymentSettingsCardProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false)
  const [isRemovingCard, setIsRemovingCard] = useState<boolean>(false)
  const [isExpenseSettingsOpen, setIsExpenseSettingsOpen] =
    useState<boolean>(false)
  const [showPaymentMethodForm, setShowPaymentMethodForm] =
    useState<boolean>(false)
  const [CCFormError, setCCFormError] = useState<string | undefined>()
  const submitCardRef = useRef<HTMLButtonElement>(null)
  const actions = useActions()
  const { data } = useAccountQuery()
  const { data: marketingContent } = useCreditCardManagerMarketingContent()
  const updateAccountMutation = useUpdateAccount()
  const isMobile = useSelector(state => state.window.width <= 520)

  const errorMessage = marketingContent?.creditCardUpdateRateLimitReached
    ? marketingContent.creditCardUpdateRateLimitReached.content
    : 'Failed to add credit card.'

  const updateAccount = async (
    data: AccountInput,
    success: () => void = noop,
    error: (error: Error | string) => void = noop,
  ) => {
    try {
      const {
        updateAccount: { account },
      } = await updateAccountMutation.mutateAsync({
        account: { ...data },
      })
      actions.updatedAccount(account)
      success()
    } catch (err) {
      error(parseError(err))
    }
  }

  const setCard = (stripeToken: Token) => {
    setIsLoading(true)
    updateAccount(
      { stripeToken: stripeToken.id },
      () => {
        setIsLoading(false)
        setShowPaymentMethodForm(false)
      },
      () => {
        setIsLoading(false)
        setCCFormError(errorMessage)
      },
    )
  }

  const removeCard = () => {
    updateAccount({ stripeToken: null }, () => {
      setIsRemovingCard(false)
    })
  }

  const paymentData =
    data?.stripeSource?.__typename === 'CreditCard'
      ? {
          cardType: data.stripeSource.brand,
          last4: data.stripeSource.last4,
          name: `${data.firstName} ${data.lastName}`,
          exp: `${data.stripeSource.expMonth}/${data.stripeSource.expYear}`,
        }
      : {
          cardType: 'visa',
          last4: '0000',
          name: 'dane joe',
          exp: '05/30',
        }
  const hasStripeSource = data?.stripeSource?.__typename === 'CreditCard'

  return (
    <Flex
      flexDirection="column"
      backgroundColor={'foreground'}
      borderRadius="default"
      inset={{ vertical: 'x2', horizontal: 'x2_5' }}
      boxShadow="light"
    >
      <Flex id="paymentInfoContainer" flexDirection="column">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          outset={{ bottom: 'x2' }}
        >
          <Div>
            <Text
              type="body"
              color="primaryHeading"
              weight="bold"
              content={'Payment Settings'}
            />
            <Text
              type="caption"
              content="Edit your payment method and expense bucket settings here."
            />
          </Div>
        </Flex>
        <Flex
          width="100%"
          alignItems="center"
          inset={{ horizontal: 'x2', vertical: 'x1_5' }}
          outset={{ top: 'x1', bottom: 'x2' }}
          style={{ backgroundColor: '#eef0f2', borderRadius: 16 }}
          id="account_payment_card"
        >
          <Flex
            style={{
              height: 42,
              width: 75,
            }}
          >
            <CreditCard
              cardType={paymentData.cardType}
              last4={paymentData.last4}
              name={paymentData.name}
              exp={paymentData.exp}
              width="100%"
              height="100%"
            />
          </Flex>
          <Flex outset={{ left: 'x2' }} id="shipping_address">
            {hasStripeSource ? (
              <Flex flexWrap="wrap">
                <Flex width="100%">
                  <Text
                    weight="semiBold"
                    type="caption"
                    content={`${paymentData.cardType} ending in ${paymentData.last4}`}
                  />
                </Flex>
                <Text content={paymentData.exp} type="caption" lineHeight={1} />
              </Flex>
            ) : (
              <Anchor
                size="xSmall"
                isDecorated={true}
                title="No Payment Method Attached"
                onClick={() => setShowPaymentMethodForm(true)}
              />
            )}
          </Flex>
          <Flex style={{ marginLeft: 'auto' }}>
            {hasStripeSource && (
              <Flex
                onClick={() => setIsRemovingCard(true)}
                inset="x1"
                cursor="pointer"
                outset={{ right: 'x1' }}
              />
            )}
            <Flex
              onClick={
                hasStripeSource
                  ? () => setIsRemovingCard(true)
                  : () => setShowPaymentMethodForm(true)
              }
              inset="x1"
              alignItems="center"
              style={{
                backgroundColor: hasStripeSource ? '#fff' : '#bf9cdd',
                borderRadius: 10,
              }}
              cursor="pointer"
            >
              {hasStripeSource ? (
                <Icon name="trash" size={16} color="#404040" />
              ) : (
                <Icon
                  name={hasStripeSource ? 'edit' : 'payment'}
                  size={16}
                  color="white"
                />
              )}
              {!hasStripeSource && (
                <Text
                  type="caption"
                  content="Add Payment"
                  outset={{ left: 'x1' }}
                  color="inverseHeading"
                  lineHeight={1.2}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          width="100%"
          alignItems="center"
          inset={{ horizontal: 'x2', vertical: 'x1_5' }}
          outset={{ top: 'x1', bottom: 'x2' }}
          style={{ backgroundColor: '#eef0f2', borderRadius: 16 }}
          id="account_expense_card"
        >
          <Flex>
            {/* TODO: Change to expense bucket card */}
            <ExpenseBucketCard
              width="75px"
              height="42px"
              expenseReload={data?.expenseReload ?? undefined}
              balance={data?.expense.amount ?? undefined}
            />
          </Flex>
          <Flex outset={{ left: 'x2' }}>
            <Flex flexWrap="wrap">
              <Flex width="100%">
                <Text
                  weight="semiBold"
                  type="caption"
                  content={`Expense: ${data?.expense.description ?? '$0.00'}`}
                />
              </Flex>
              <Text
                content={`Auto Reload: ${
                  data?.expenseReload?.isEnabled ? 'ON' : 'OFF'
                }`}
                type="caption"
                lineHeight={1}
              />
            </Flex>
          </Flex>
          <Flex
            onClick={() => setIsExpenseSettingsOpen(true)}
            inset="x1"
            alignItems="center"
            style={{
              backgroundColor: '#bf9cdd',
              borderRadius: 10,
              marginLeft: 'auto',
            }}
            cursor="pointer"
          >
            <Icon name={'shopping_cart'} size={16} color="white" />
            <Text
              type="caption"
              content="Purchase"
              outset={{ left: 'x1' }}
              color="inverseHeading"
              lineHeight={1.2}
            />
          </Flex>
        </Flex>
      </Flex>
      <Dialog
        isOpen={showPaymentMethodForm}
        onClose={() => setShowPaymentMethodForm(false)}
        title="Add Payment Method"
        padding={`${isMobile ? '60px' : '0px'} 40px 40px 40px`}
        sheetBgColorOverride={props.formBackgroundOverride}
      >
        {showPaymentMethodForm && !hasStripeSource && (
          <>
            <Spacer orientation="vertical" space="x2" />
            {/* New Form */}
            {/* Old Form */}
            <CreditCardForm
              returnAddress={data?.shippingAddress ?? undefined}
              onSuccess={token => setCard(token)}
              onError={() => setCCFormError(errorMessage)}
              isCCFormComplete={isComplete => {
                setIsFormComplete(isComplete)
              }}
              isLoading={isLoading}
            >
              <button
                form="addCreditCardForm"
                type="submit"
                ref={submitCardRef}
                style={{ display: 'none' }}
              />
              <Button
                background="#404040"
                padding={'12px 16px'}
                borderRadius="small"
                onClick={() => setCCFormError(undefined)}
                isSubmit={true}
                isDisabled={!isFormComplete}
                title="Add Card"
                textSize="12px"
              />
            </CreditCardForm>
            {CCFormError && (
              <Scrollable maxHeight={100}>
                <Text
                  type="caption"
                  color="danger"
                  content={CCFormError}
                  inset={{ top: 'x_5' }}
                />
              </Scrollable>
            )}
          </>
        )}
      </Dialog>
      <ConfirmDialog
        title="Delete Payment Method"
        description="Are you sure you want to delete this payment method from your account?"
        isOpen={isRemovingCard}
        accept={{
          title: updateAccountMutation.isLoading
            ? 'Deleting...'
            : 'Delete Payment',
          onClick: () => removeCard(),
          isDisabled: updateAccountMutation.isLoading,
        }}
        decline={{
          title: 'Cancel',
          onClick: () => setIsRemovingCard(false),
        }}
        primaryAction="accept"
      />
      {/* Expense UI */}
      <Dialog
        isOpen={isExpenseSettingsOpen}
        onClose={() => setIsExpenseSettingsOpen(false)}
      >
        <ExpenseBucket />
      </Dialog>
    </Flex>
  )
}
