import { graphqlClient } from 'src/graphql/client'
import {
  AccountInput,
  AddFreeCampaignDocument,
  AddFreeCampaignMutationVariables,
  CancelStripeSubscriptionDocument,
  CancelStripeSubscriptionMutationVariables,
  ChangeSubscriptionDocument,
  ChangeSubscriptionMutationVariables,
  ClaimRewardDocument,
  ClaimRewardMutationVariables,
  CreateAccountDocument,
  CreateAccountMutationVariables,
  CreateAffiliateDocument,
  CreateAffiliateMutationVariables,
  CreateCampaignDocument,
  CreateCampaignMutationVariables,
  CreateCardDocument,
  CreateCardMutationVariables,
  CreateContactRequestDocument,
  CreateContactRequestMutationVariables,
  CreateFreeAffiliateDocument,
  CreateFreeAffiliateMutationVariables,
  CreateOrderDocument,
  CreateOrderMutationVariables,
  CreateOrUpdateBackPanelDocument,
  CreateOrUpdateBackPanelMutationVariables,
  CreateSparseUserDocument,
  CreateSparseUserMutationVariables,
  CreateUserLoginLinkDocument,
  CreateUserLoginLinkMutationVariables,
  DeleteContactRequestDocument,
  DeleteContactRequestMutationVariables,
  DeleteOrderDocument,
  DeleteOrderMutationVariables,
  GetOrCreateEditableCardForRecipientDocument,
  GetOrCreateEditableCardForRecipientMutationVariables,
  LoginUserDocument,
  LoginUserMutationVariables,
  PurchaseProductDocument,
  PurchaseProductMutationVariables,
  RevertBulkOrderDocument,
  RevertBulkOrderMutationVariables,
  SendMagicLinkDocument,
  SendMagicLinkMutationVariables,
  UpdateAccountDocument,
  UpdateAccountMutationVariables,
  UpdateCampaignDocument,
  UpdateCampaignMutationVariables,
  UpdateCardDocument,
  UpdateCardMutationVariables,
  UpdateOrderDocument,
  UpdateOrderMutationVariables,
  UpdateRequestedContactDocument,
  UpdateRequestedContactMutationVariables,
  UpdateUserLabelOverridesDocument,
  UpdateUserLabelOverridesMutationVariables,
  UpgradeToAffiliateDocument,
  UpgradeToAffiliateMutationVariables,
  UpgradeToGreenerStillAffiliateDocument,
  UpgradeToGreenerStillAffiliateMutationVariables,
  UpgradeToReferringCustomerDocument,
  UpgradeToReferringCustomerMutationVariables,
  ValidateEmailVerificationCodeDocument,
  ValidateEmailVerificationCodeMutationVariables,
  VerifyPhoneNumberDocument,
  VerifyPhoneNumberMutationVariables,
} from 'src/graphql/generated/graphql'
import * as graphql from 'src/graphql/generated/graphql'
import { sanitizeEmail } from 'src/helpers/sanitizeEmail'

const sanitizeAccountInput = (accountInput: AccountInput) => {
  return accountInput.email
    ? {
        ...accountInput,
        email: sanitizeEmail(accountInput.email),
      }
    : accountInput
}

export const acceptCampaignShare = async (
  variables: graphql.AcceptCampaignShareMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.AcceptCampaignShareDocument,
    variables,
  )
  return res
}

export const acceptMembershipInvite = async (
  variables: graphql.AcceptMembershipInviteMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.AcceptMembershipInviteDocument,
    variables,
  )
  return res
}

export const acceptSharedContact = async (
  variables: graphql.AcceptSharedContactMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.AcceptSharedContactDocument,
    variables,
  )
  return res
}

export const bulkDeleteCards = async (
  variables: graphql.BulkDeleteCardsMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.BulkDeleteCardsDocument,
    variables,
  )
  return res
}

export const bulkDeleteSendableCards = async (
  variables: graphql.BulkDeleteSendableCardsMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.BulkDeleteSendableCardsDocument,
    variables,
  )
  return res
}

export const declineMembershipInvite = async (
  variables: graphql.DeclineMembershipInviteMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.DeclineMembershipInviteDocument,
    variables,
  )
  return res
}

export const deleteCampaignShare = async (
  variables: graphql.DeleteCampaignShareMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.DeleteCampaignShareDocument,
    variables,
  )
  return res
}

export const deleteContactShare = async (
  variables: graphql.DeleteContactShareMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.DeleteContactShareDocument,
    variables,
  )
  return res
}

export const deleteReminder = async (
  variables: graphql.DeleteReminderMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.DeleteReminderDocument,
    variables,
  )
  return res
}

export const denyNotification = async (
  variables: graphql.DenyNotificationMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.DenyNotificationDocument,
    variables,
  )
  return res
}

export const cancelStripeSubscription = async (
  variables: CancelStripeSubscriptionMutationVariables,
) => {
  const res = await graphqlClient.request(
    CancelStripeSubscriptionDocument,
    variables,
  )
  return res
}

export const createAccount = async (
  variables: CreateAccountMutationVariables,
) => {
  const res = await graphqlClient.request(CreateAccountDocument, {
    ...variables,
    account: sanitizeAccountInput(variables.account),
  })

  return res
}

export const createAffiliate = async (
  variables: CreateAffiliateMutationVariables,
) => {
  const res = await graphqlClient.request(CreateAffiliateDocument, variables)

  return res
}

export const upgradeToReferringCustomer = async (
  variables: UpgradeToReferringCustomerMutationVariables,
) => {
  const res = await graphqlClient.request(
    UpgradeToReferringCustomerDocument,
    variables,
  )

  return res
}

export const createFreeAffiliate = async (
  variables: CreateFreeAffiliateMutationVariables,
) => {
  return await graphqlClient.request(CreateFreeAffiliateDocument, variables)
}

export const createUserLoginLink = async (
  variables: CreateUserLoginLinkMutationVariables,
) => await graphqlClient.request(CreateUserLoginLinkDocument, variables)

export const favoriteSendableCard = async (
  variables: graphql.FavoriteSendableCardMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.FavoriteSendableCardDocument,
    variables,
  )
  return res
}

export const getOrCreateEditableCardForRecipient = async (
  variables: GetOrCreateEditableCardForRecipientMutationVariables,
) =>
  await graphqlClient.request(
    GetOrCreateEditableCardForRecipientDocument,
    variables,
  )

export const verifyPhoneNumber = async (
  variables: VerifyPhoneNumberMutationVariables,
) => graphqlClient.request(VerifyPhoneNumberDocument, variables)

export const validateEmailVerificationCode = async (
  variables: ValidateEmailVerificationCodeMutationVariables,
) => graphqlClient.request(ValidateEmailVerificationCodeDocument, variables)

export const sendMagicLink = async (
  variables: SendMagicLinkMutationVariables,
) => graphqlClient.request(SendMagicLinkDocument, variables)

export const loginUser = async (variables: LoginUserMutationVariables) =>
  graphqlClient.request(LoginUserDocument, variables)

export const createCard = async (variables: CreateCardMutationVariables) =>
  graphqlClient.request(CreateCardDocument, variables)

export const digitalCardCreate = async (
  variables: graphql.DigitalCardCreateMutationVariables,
) =>
  (await graphqlClient.request(graphql.DigitalCardCreateDocument, variables))
    .digitalCardCreate.digitalCard

export const digitalCardOrderCreate = async (
  variables: graphql.DigitalCardOrderCreateMutationVariables,
) =>
  (
    await graphqlClient.request(
      graphql.DigitalCardOrderCreateDocument,
      variables,
    )
  ).digitalCardOrderCreate.order

export const digitalCardOrderPay = async (
  variables: graphql.DigitalCardOrderPayMutationVariables,
) =>
  (await graphqlClient.request(graphql.DigitalCardOrderPayDocument, variables))
    .digitalCardOrderPay.digitalCard

export const digitalCardUpdate = async (
  variables: graphql.DigitalCardUpdateMutationVariables,
) =>
  (await graphqlClient.request(graphql.DigitalCardUpdateDocument, variables))
    .digitalCardUpdate.digitalCard

export const digitalCardDelete = async (
  variables: graphql.DigitalCardDeleteMutationVariables,
) => await graphqlClient.request(graphql.DigitalCardDeleteDocument, variables)

export const digitalCardRecipientCreate = async (
  variables: graphql.DigitalCardRecipientCreateMutationVariables,
) =>
  (
    await graphqlClient.request(
      graphql.DigitalCardRecipientCreateDocument,
      variables,
    )
  ).digitalCardRecipientCreate.digitalCardRecipient

export const requesPhysicalCard = async (
  variables: graphql.RequestPhysicalCardMutationVariables,
) =>
  (await graphqlClient.request(graphql.RequestPhysicalCardDocument, variables))
    .requestPhysicalCard.requested

export const removeUserUpsell = async (
  variables: graphql.RemoveUserUpsellMutationVariables,
) => await graphqlClient.request(graphql.RemoveUserUpsellDocument, variables)

export const createSparseUser = async (
  variables: CreateSparseUserMutationVariables,
) => graphqlClient.request(CreateSparseUserDocument, variables)

export const purchaseProduct = async (
  variables: PurchaseProductMutationVariables,
) => graphqlClient.request(PurchaseProductDocument, variables)

export const unfavoriteSendableCard = async (
  variables: graphql.UnfavoriteSendableCardMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.UnfavoriteSendableCardDocument,
    variables,
  )
  return res
}

export const upgradeToAffiliate = async (
  variables: UpgradeToAffiliateMutationVariables,
) => await graphqlClient.request(UpgradeToAffiliateDocument, variables)

export const upgradeToGreenerStillAffiliate = async (
  variables: UpgradeToGreenerStillAffiliateMutationVariables,
) =>
  await graphqlClient.request(UpgradeToGreenerStillAffiliateDocument, variables)

export const updateAccount = async (
  variables: UpdateAccountMutationVariables,
) =>
  await graphqlClient.request(UpdateAccountDocument, {
    ...variables,
    account: sanitizeAccountInput(variables.account),
  })

export const updateCard = async (variables: UpdateCardMutationVariables) =>
  await graphqlClient.request(UpdateCardDocument, variables)

export const updateUserLabelOverrides = async (
  variables: UpdateUserLabelOverridesMutationVariables,
) => await graphqlClient.request(UpdateUserLabelOverridesDocument, variables)

export const updateCampaign = async (
  variables: UpdateCampaignMutationVariables,
) => await graphqlClient.request(UpdateCampaignDocument, variables)

export const createOrder = async (variables: CreateOrderMutationVariables) =>
  await graphqlClient.request(CreateOrderDocument, variables)

export const deleteOrder = async (variables: DeleteOrderMutationVariables) =>
  await graphqlClient.request(DeleteOrderDocument, variables)

export const createCampaign = async (
  variables: CreateCampaignMutationVariables,
) => await graphqlClient.request(CreateCampaignDocument, variables)

export const updateOrder = async (variables: UpdateOrderMutationVariables) =>
  await graphqlClient.request(UpdateOrderDocument, variables)

export const updateProductionInfo = async (
  variables: graphql.UpdateProductionInfoMutationVariables,
) =>
  await graphqlClient.request(graphql.UpdateProductionInfoDocument, variables)

export const updateReminder = async (
  variables: graphql.UpdateReminderMutationVariables,
) => {
  const res = await graphqlClient.request(
    graphql.UpdateReminderDocument,
    variables,
  )
  return res
}

export const revertBulkOrder = async (
  variables: RevertBulkOrderMutationVariables,
) => await graphqlClient.request(RevertBulkOrderDocument, variables)

export const createOrUpdateBackPanel = async (
  variables: CreateOrUpdateBackPanelMutationVariables,
) => await graphqlClient.request(CreateOrUpdateBackPanelDocument, variables)

export const deletePanelLayout = async (
  variables: graphql.DeletePanelLayoutMutationVariables,
) => await graphqlClient.request(graphql.DeletePanelLayoutDocument, variables)

export const addFreeCampaign = async (
  variables: AddFreeCampaignMutationVariables,
) => await graphqlClient.request(AddFreeCampaignDocument, variables)

export const changeSubscription = async (
  variables: ChangeSubscriptionMutationVariables,
) => await graphqlClient.request(ChangeSubscriptionDocument, variables)

export const createContactRequest = async (
  variables: CreateContactRequestMutationVariables,
) => await graphqlClient.request(CreateContactRequestDocument, variables)

export const deleteContactRequest = async (
  variables: DeleteContactRequestMutationVariables,
) => await graphqlClient.request(DeleteContactRequestDocument, variables)

export const updateRequestedContact = async (
  variables: UpdateRequestedContactMutationVariables,
) => await graphqlClient.request(UpdateRequestedContactDocument, variables)

export const claimReward = async (variables: ClaimRewardMutationVariables) =>
  await graphqlClient.request(ClaimRewardDocument, variables)
