import React from 'react'
import { Flex } from '@sendoutcards/quantum-design-ui'
import { PromptingsPoweredBySocLogo } from 'src/design_system/atoms/icons/PromptingsPoweredBySocLogo'

export const SplashHeader = () => {
  return (
    <Flex
      height="64px"
      width="100%"
      position="fixed"
      top="0"
      left="0"
      backgroundColor="foreground"
      inset={{ vertical: '14px', right: 'x2' }}
    >
      <Flex id="logo">
        <PromptingsPoweredBySocLogo size={200} />
      </Flex>
    </Flex>
  )
}
