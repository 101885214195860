import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { FeatureType } from 'src/pricing_page/hooks/usePlanIncludes'
import { IconType } from 'src/design_system/atoms/icons/types'
import { Memoized } from 'src/hooks/dependencies'

export type UpsaleListProps = {
  features?: Memoized<FeatureType[]>
  includedIconColor?: string
  disabledColor?: string
  textColorOverride?: string
}

export const UpsaleFeatureList = ({
  features,
  includedIconColor = '#FECD39',
  disabledColor = '#D1D5DB',
  textColorOverride,
}: UpsaleListProps) => {
  return (
    <Flex
      flexDirection="column"
      rowGap="12px"
      width="338px"
      backgroundColor="#fff"
      style={{
        padding: '24px',
        borderRadius: '24px',
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
        backdropFilter: 'blur(75px)',
      }}
    >
      {features?.map((feature, index) => {
        const isNotIncludedFeature = !feature.isIncluded
        const icon = {
          size: isNotIncludedFeature ? 12 : 16,
          name: isNotIncludedFeature ? 'x' : 'premium_crown',
          fill: isNotIncludedFeature ? disabledColor : includedIconColor,
        }
        const textColor = textColorOverride
          ? textColorOverride
          : isNotIncludedFeature
            ? disabledColor
            : '#4b5563'
        return (
          <Flex key={index} alignItems="center" justifyContent="flex-start">
            <Icon
              size={icon.size}
              name={icon.name as IconType}
              color={icon.fill}
            />
            <Text
              style={{
                fontSize: '14px',
                color: textColor,
                lineHeight: '1.25rem',
                fontWeight: 500,
                fontFamily: 'Montserrat, sans-serif',
              }}
              outset={{ left: 'x1' }}
              type={'title'}
            >
              {feature.feature}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}
