import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'

type BasicToasterProps = {
  message: string
}

const BasicToaster: React.FC<BasicToasterProps> = props => {
  const { message } = props

  return (
    <AnimatePresence>
      <ToasterNotification
        backgroundColor={{
          swatch: 'danger',
          shade: '_500',
        }}
        icon={{
          size: 'xSmall',
          name: 'information',
          primaryColor: 'inverseHeadingText',
          iconContainerColor: { swatch: 'success', shade: '_400' },
        }}
        label={{
          color: 'inverseHeading',
          type: 'footnote',
          content: message,
        }}
      />
    </AnimatePresence>
  )
}

export default BasicToaster
