import type React from 'react'
import { Address } from 'src/graphql/generated/graphql'
import { Flex, Div, Text } from '@sendoutcards/quantum-design-ui'

interface AddressDisplayCardProps {
  address: Pick<
    Address,
    'address1' | 'city' | 'state' | 'postalCode' | 'firstName' | 'lastName'
  >
  onEdit?: () => void
}

export const AddressDisplayCard = ({
  address,
  onEdit,
}: AddressDisplayCardProps) => {
  const buttonStyle: React.CSSProperties = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#9ca3af',
    padding: 0,
  }

  return (
    <Div
      width="100%"
      maxWidth="600px"
      backgroundColor="foreground"
      boxShadow={'0px 4px 12px 0px rgba(0, 0, 0, 0.04)'}
      inset={{ vertical: '12px', horizontal: 'x2' }}
      style={{ borderRadius: 20 }}
    >
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Text
          type="largeBody"
          color="primaryHeading"
          content="My Address"
          weight="bold"
        />
        <button onClick={onEdit} style={buttonStyle} aria-label="Edit address">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
            <path d="m15 5 4 4"></path>
          </svg>
        </button>
      </Flex>
      <Div>
        <Text
          type="caption"
          content={address.address1}
          outset={{ vertical: 'x1' }}
          style={{ lineHeight: '1rem' }}
        />
        <Text
          type="caption"
          content={`${address.city}, ${address.state}, ${address.postalCode}`}
          outset={{ vertical: 'x1' }}
          style={{ lineHeight: '1rem' }}
        />
      </Div>
    </Div>
  )
}
