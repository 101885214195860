import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { StatusPill, StatusType } from './StatusPill'

type SettingCardProps = {
  title: string
  description: string
  status?: StatusType
  customStatusTitles?: string[]
  onClick: () => void
}

export const SettingCard = ({
  title,
  description,
  status,
  customStatusTitles,
  onClick,
}: SettingCardProps) => {
  return (
    <Flex
      onClick={onClick}
      inset={{ vertical: '12px', horizontal: '16px' }}
      style={{
        borderRadius: 20,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04)',
      }}
      width="100%"
      cursor="pointer"
      backgroundColor={'foreground'}
      columnGap="x1"
    >
      <Flex id="contentWrapper" flexDirection={'column'} width="100%">
        <Flex id="titleStatusArrow">
          <Text
            content={title}
            type="largeBody"
            weight="bold"
            color="primaryHeading"
            style={{ fontSize: 19 }}
          />
          {status && (
            <Flex outset={{ left: 'x2' }} width={customStatusTitles && '100%'}>
              {customStatusTitles ? (
                customStatusTitles.map(title => (
                  <StatusPill status="custom" customStatusTitle={title} />
                ))
              ) : (
                <StatusPill status={status} />
              )}
            </Flex>
          )}
          <Flex
            style={{
              position: 'relative',
              transform: 'rotate(180deg)',
              marginLeft: 'auto',
            }}
          >
            <Icon name="arrow" />
          </Flex>
        </Flex>
        <Flex>
          <Text
            content={description}
            type="footnote"
            style={{ lineHeight: '1.1rem' }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
