/**
 This purpose of this hook is to perform checks on users and current card to determine if the user
 should be show a subscription upsale. It's a hard code solution for now that checks if the user is created
 after 04/12/2025 (We grandfathered all previous accounts), if the card is premium and if they have a premium 
 feature asset. If so, we should show upsale. 
 */

import { FullAccessFeatureType } from 'src/graphql/generated/graphql'
import { queryKeyStore, useGetSendableCard, useUpsells } from 'src/react_query'
import { useFeatureAccess } from './useFeatureAccess'
import { useEffect, useSelector } from '.'
import usePlanInfo from './usePlanInfo'
import { useQueryClient } from '@tanstack/react-query'

// This props would be to override the upsale that might return from the backend
export const usePremiumCardSubscriptionUpsale = () => {
  const { upsellSlug, sendableCardId, planToDisplay, triggerAction } =
    useSelector(state => state.upsell)
  const queryClient = useQueryClient()

  // 04/12/2025 > Right now, 04/08/25
  const GRANDFATHERED_DATE = new Date(2025, 3, 8)
  const account = useSelector(state => state.user.account)
  const { hasFeatureAccess: hasFreePremiumCards } = useFeatureAccess([
    FullAccessFeatureType.FreePremiumCards,
  ])
  const { data: sendableCardData } = useGetSendableCard({ id: sendableCardId })

  const isPremiumCard = sendableCardData?.sendableCard.isPremium ?? false
  const isGrandFathered = account?.dateJoined
    ? new Date(account.dateJoined) < GRANDFATHERED_DATE
    : false

  useEffect(() => {
    queryClient.invalidateQueries(queryKeyStore.upsell._def)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAction])

  // An array of upsells that is returned from the backend where the user
  // meets certain conditions
  const { data: upsellsData } = useUpsells({
    enabled: !!account,
  })

  // In this spagetti we determine which upsell to display, if something is not triggering,
  // bellow is the logic mistake.
  const planToUpsell = planToDisplay
    ? { planId: planToDisplay, slug: 'PREMIUM' }
    : triggerAction === 'DIGITAL' &&
        sendableCardId &&
        !hasFreePremiumCards &&
        isPremiumCard &&
        !isGrandFathered
      ? { planId: '189', slug: 'PREMIUM' }
      : triggerAction === 'PHYSICAL' &&
          sendableCardId &&
          !hasFreePremiumCards &&
          isPremiumCard &&
          !isGrandFathered
        ? { planId: '190', slug: undefined }
        : upsellsData?.find(
              upsell => upsell.slug === 'upsell plan 190 immediate',
            )
          ? { planId: '190', slug: 'upsell plan 190 immediate' }
          : upsellsData?.find(
                upsell => upsell.slug === 'upsell plan 184 immediate',
              )
            ? { planId: '184', slug: 'upsell plan 184 immediate' }
            : triggerAction === 'DIGITAL' &&
                upsellsData?.find(
                  upsell => upsell.slug === 'upsell plan 189 next digital send',
                )
              ? { planId: '189', slug: 'upsell plan 189 next digital send' }
              : triggerAction === 'PHYSICAL' &&
                  upsellsData?.find(
                    upsell =>
                      upsell.slug === 'upsell plan 190 next physical send',
                  )
                ? { planId: '190', slug: 'upsell plan 190 next physical send' }
                : triggerAction === 'PHYSICAL' &&
                    upsellsData?.find(
                      upsell =>
                        upsell.slug === 'upsell plan 191 next physical send',
                    )
                  ? {
                      planId: '191',
                      slug: 'upsell plan 191 next physical send',
                    }
                  : undefined

  const {
    formattedPlan,
    features,
    saleorVariantId,
    upsaleInfo,
    plan,
    formattedRelatedPlan,
  } = usePlanInfo(planToUpsell?.planId)

  const upsaleMiniImage = upsaleInfo.fullUpsale.image
  const fullScreenUpsaleImage = upsaleInfo.miniUpsale.image

  if (formattedPlan && saleorVariantId) {
    return {
      plan: plan,
      plans: [
        ...(formattedRelatedPlan.optionId !== ''
          ? [
              {
                title: formattedRelatedPlan.title,
                price: formattedRelatedPlan.price,
                optionId: formattedRelatedPlan.optionId,
                sku: formattedRelatedPlan.sku,
                isMonthly: false,
              },
            ]
          : []),
        {
          title: formattedPlan.title,
          price: formattedPlan.price,
          optionId: formattedPlan.optionId,
          sku: formattedPlan.sku,
          isMonthly: false,
        },
      ],
      features: features,
      title: upsaleInfo.fullUpsale.title,
      description: upsaleInfo.fullUpsale.description,
      selectedOption: formattedPlan.optionId,
      upsaleMiniImage: upsaleMiniImage,
      upsaleFullImage: fullScreenUpsaleImage,
      shouldShowUpsell: true,
      saleorVariantId: saleorVariantId,
      planId: planToUpsell?.planId,
      upsellSlug: planToUpsell?.slug,
      continueButtonTitle:
        planToUpsell?.slug === 'PREMIUM'
          ? triggerAction === 'DIGITAL'
            ? 'Continue for $3.00'
            : 'Continue for $5.00'
          : undefined,
    }
  }

  // just a basic return in case of ...
  return {
    upsellSlug: upsellSlug,
    shouldShowUpsell: false,
  }
}
