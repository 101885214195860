import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import {
  HOCSpacingKeys,
  LiteralUnion,
  SpacingDirection,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import React from 'react'

type UpsaleFooterProps = {
  onViewAll?: () => void
  onShowTerms?: () => void
  inset?: SpacingDirection | LiteralUnion<HOCSpacingKeys>
}

export const UpsaleFooter = (props: UpsaleFooterProps) => {
  const fontFamily = 'Montserrat, sans-serif'

  const disclaimerStyles = {
    fontFamily: fontFamily,
    fontSize: '10px',
    fontWeight: 600,
    color: '6b7280',
    paddingTop: '10px',
    paddingBottom: '6px',
    whiteSpace: 'pre',
    lineHeight: 1.5,
    textAlign: 'center' as const,
  }

  const termStyles = {
    base: {
      fontFamily: fontFamily,
      fontSize: '10px',
      fontWeight: 600,
      color: '#6b7280',
    },
    link: {
      fontWeight: 700,
      textDecoration: 'underline',
      paddingLeft: '3px',
      cursor: 'pointer',
    },
  }
  return (
    <Flex flexDirection="column" alignItems="center" inset={props.inset}>
      <span
        onClick={props.onViewAll}
        style={{
          textDecoration: 'underline',
          fontFamily: fontFamily,
          fontSize: '12px',
          fontWeight: 700,
          color: '#153647',
          cursor: 'pointer',
        }}
      >
        View All Plans
      </span>
      {props.onShowTerms && (
        <>
          <Text
            type="body"
            style={{ ...disclaimerStyles }}
            content={
              'Our cards are DELIVERED CARDS.\nThis means stamps are included with ALL cards.'
            }
          />
          <span style={{ ...termStyles.base }}>
            Cancel anytime. Renews automatically.
            <span
              onClick={props.onShowTerms}
              style={{ ...termStyles.base, ...termStyles.link }}
            >
              Terms Apply
            </span>
          </span>
        </>
      )}
    </Flex>
  )
}
