import { useAccountQuery, usePlans } from 'src/react_query'
import { useFeatureAccess } from './useFeatureAccess'
import { FullAccessFeatureType } from 'src/graphql/generated/graphql'

type UplsaleType = 'lifetimeAct' | 'monthlyAct' | 'casualSender'

export const useCurrentUpsale = () => {
  const getUpsalePlan = (upsale?: UplsaleType) =>
    ({
      lifetimeAct: {
        id: '195',
        title: 'Want this card to be free? Add the $10 Unlimited Today',
        btnTitle: 'Unlock',
      },
      monthlyAct: {
        id: '189',
        title: 'Want this card to be free? Add the $10 Unlimeted Today',
        btnTitle: 'Unlock',
      },
      casualSender: {
        id: '190',
        title: 'Want this card to be free? Add the $10 Unlimeted Today',
        btnTitle: 'Unlock',
      },
    })[upsale!]

  const { data: account } = useAccountQuery()
  const { hasFeatureAccess: hasLifetimeAct } = useFeatureAccess([
    FullAccessFeatureType.UnlimitedActsSubDiscount,
  ])
  const { data: plans } = usePlans({ suspense: false })

  const currentSoldPlans = ['184', '189', '190', '191']
  const legacyPlans = plans?.filter(
    plan =>
      !currentSoldPlans.includes(plan.id) && !plan.isAddon && plan.id !== '1',
  )

  const currentActivePlan = account?.plan
  const hasNoSubscription = currentActivePlan?.id === '1'
  const hasLegacyPlan = legacyPlans?.some(
    plan => plan.id === currentActivePlan?.id,
  )
  const shouldUpsaleLifetimeAct = hasLegacyPlan && !hasLifetimeAct
  const shouldUpsaleMonthlyAct = hasNoSubscription && !hasLifetimeAct

  return {
    plan: getUpsalePlan(
      shouldUpsaleLifetimeAct
        ? 'lifetimeAct'
        : shouldUpsaleMonthlyAct
          ? 'monthlyAct'
          : undefined,
    ),
  }
}
