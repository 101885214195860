import { ActionCreator } from '@sendoutcards/core'
import { UpsellSlug } from '../types/upsell'

export const setSendableCardId = ActionCreator(
  'SET_SENDABLE_CARD_ID',
  (sendableCardId?: string) => ({ sendableCardId }),
)

export const setShouldShowUpsell = ActionCreator(
  'SET_SHOULD_SHOW_UPSELL',
  (shouldShowUpsell: boolean) => ({ shouldShowUpsell }),
)

export const setUpsellSlug = ActionCreator(
  'SET_UPSELL_SLUG',
  (upsellSlug?: UpsellSlug) => ({ upsellSlug }),
)

export const setPlanToDisplay = ActionCreator(
  'SET_PLAN_TO_DISPLAY',
  (planToDisplay?: string) => ({ planToDisplay }),
)

export const setTriggerAction = ActionCreator(
  'SET_TRIGGER_ACTION',
  (triggerAction?: string) => ({ triggerAction }),
)

export const resetUpsellState = ActionCreator('RESET_UPSELL_STATE')
