import Action from 'src/redux/action'
import * as actions from 'src/redux/actions/upsell'

import { UpsellSlug } from '../types/upsell'

export type UpsellState = {
  shouldShowUpsell?: boolean
  upsellSlug?: UpsellSlug
  sendableCardId?: string
  planToDisplay?: string
  triggerAction?: string
}

const initialState: UpsellState = {
  shouldShowUpsell: undefined,
  upsellSlug: undefined,
  sendableCardId: undefined,
  planToDisplay: undefined,
  triggerAction: undefined,
}

const upsell = (
  state: UpsellState = initialState,
  action: Action,
): UpsellState => {
  switch (action.type) {
    case actions.setSendableCardId.type:
      return {
        ...state,
        sendableCardId: action.sendableCardId,
      }
    case actions.setUpsellSlug.type:
      return {
        ...state,
        upsellSlug: action.upsellSlug,
      }
    case actions.setShouldShowUpsell.type:
      return {
        ...state,
        shouldShowUpsell: action.shouldShowUpsell,
      }
    case actions.setPlanToDisplay.type:
      return {
        ...state,
        planToDisplay: action.planToDisplay,
      }
    case actions.setTriggerAction.type:
      return {
        ...state,
        triggerAction: action.triggerAction,
      }
    case actions.resetUpsellState.type:
      return initialState

    default:
      return state
  }
}

export default upsell
