import React from 'react'
import { Button, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { useExclusiveOffer } from 'src/react_query'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useActions } from 'src/hooks'

export const ExclusiveOffer = () => {
  const { data } = useExclusiveOffer()
  const getDaysRemaining = (expiresAt: string): number => {
    const now = new Date()
    const expiry = new Date(expiresAt)
    const msPerDay = 1000 * 60 * 60 * 24
    const diffInMs = expiry.getTime() - now.getTime()
    return Math.max(Math.ceil(diffInMs / msPerDay), 0)
  }
  const actions = useActions()
  if (!data) return <></>
  const daysRemaining = getDaysRemaining(data.expiresAt)

  return (
    <Flex
      flexDirection="column"
      columnGap="x1"
      backgroundColor="#fff"
      borderRadius="20px"
      width="100%"
      inset="12px"
      justifyContent="center"
    >
      <Flex flexDirection="column" columnGap="12px" rowGap="12px" width="100%">
        <Flex
          width="100%"
          backgroundColor="#F7F7FE"
          borderRadius="16px"
          inset="8px"
          justifyContent="center"
          alignItems="center"
          columnGap="12px"
          rowGap="12px"
        >
          <Flex>
            <Icon size={100} name="trophy_badge" />
          </Flex>
          <Flex flexDirection="column">
            <Text
              type={'body'}
              content="My Free Trial Status"
              style={{
                fontSize: '12px',
                color: '#A9A4A6',
                fontWeight: 'bold',
                lineHeight: 1.4,
              }}
            />
            <Text
              type={'body'}
              content={`${daysRemaining} day${daysRemaining !== 1 ? 's' : ''} remaining`}
              style={{
                fontSize: '18px',
                color: '#444444',
                fontWeight: 'bold',
                lineHeight: 1.4,
              }}
            />
            <Text
              type={'body'}
              content="Exclusive Card Trial"
              style={{
                fontSize: '14px',
                color: '#A9A4A6',
                fontWeight: 'bold',
                lineHeight: 1.4,
              }}
            />
          </Flex>
        </Flex>
        <Text
          content="Available Free Card Sends"
          type={'body'}
          outset={{ left: 'x1' }}
          style={{ fontSize: '12px', lineHeight: 1, fontWeight: 600 }}
        />
        <Flex width="100%" columnGap="12px" rowGap="12px">
          <Flex
            backgroundColor="#F7F7FE"
            borderRadius="16px"
            inset={{ vertical: '8px', horizontal: '16px' }}
            justifyContent="center"
            alignItems="center"
            columnGap="x1"
            width="100%"
          >
            <Flex>
              <Text
                type={'body'}
                content={data.remainingActSends}
                style={{
                  fontSize: '32px',
                  color: '#444444',
                  fontWeight: 'bold',
                  lineHeight: 1.2,
                  fontFamily: 'Montserrat',
                }}
              />
            </Flex>
            <Flex flexDirection="column">
              <Text
                type={'body'}
                content="Digital Cards"
                style={{
                  fontSize: '18px',
                  color: '#444444',
                  fontWeight: 'bold',
                  lineHeight: 1.4,
                }}
              />
              <Text
                type={'body'}
                content="Remaining"
                style={{
                  fontSize: '14px',
                  color: '##A9A4A6',
                  fontWeight: 'bold',
                  lineHeight: 1.2,
                }}
              />
            </Flex>
          </Flex>
          <Flex
            backgroundColor="#F7F7FE"
            borderRadius="16px"
            inset={{ vertical: '8px', horizontal: '16px' }}
            justifyContent="center"
            alignItems="center"
            columnGap="12px"
            rowGap="12px"
            width="100%"
          >
            <Flex>
              <Text
                type={'body'}
                content={data.remainingCardSends}
                style={{
                  fontSize: '32px',
                  color: '#444444',
                  fontWeight: 'bold',
                  lineHeight: 1.4,
                  fontFamily: 'Montserrat',
                }}
              />
            </Flex>
            <Flex flexDirection="column">
              <Text
                type={'body'}
                content="Printed Cards"
                style={{
                  fontSize: '18px',
                  color: '#444444',
                  fontWeight: 'bold',
                  lineHeight: 1.4,
                }}
              />
              <Text
                type={'body'}
                content="Remaining"
                style={{
                  fontSize: '14px',
                  color: '##A9A4A6',
                  fontWeight: 'bold',
                  lineHeight: 1.4,
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex inset={{ top: '12px' }} width="100%">
        <Button
          onClick={actions.openCatalog}
          title="Send a Free Card"
          fullWidth
          backgroundColorOverride="#444444"
          fontWeight="bold"
        />
      </Flex>
    </Flex>
  )
}
