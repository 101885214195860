import React from 'react'
import { SVGProps } from './types'

export const Logout = ({ size = 24, color = '#E7515D', onClick }: SVGProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.27489 0.200195C7.447 0.212395 7.61912 0.220158 7.79013 0.236794C8.14099 0.268957 8.48191 0.336609 8.7787 0.545112C9.13948 0.799086 9.30608 1.1684 9.37669 1.59095C9.41862 1.84493 9.43958 2.10112 9.43958 2.35842C9.43958 4.12182 9.43958 5.88411 9.43958 7.64752C9.43958 7.93587 9.4142 8.22312 9.35793 8.50704C9.22554 9.17691 8.78642 9.605 8.11451 9.72145C7.71732 9.79021 7.31681 9.79576 6.91631 9.8013C6.73868 9.80352 6.61511 9.71369 6.56326 9.56175C6.51581 9.42201 6.55222 9.30112 6.65373 9.19909C6.72655 9.127 6.81922 9.10482 6.91962 9.10482C7.26496 9.10482 7.61029 9.09594 7.95342 9.04382C8.41019 8.97395 8.63527 8.74437 8.69926 8.2819C8.72684 8.08559 8.74119 7.88929 8.7456 7.69077C8.7809 6.07709 8.76104 4.46452 8.75773 2.85084C8.75773 2.44936 8.76767 2.04456 8.68492 1.64641C8.59776 1.23051 8.39144 1.0331 7.97108 0.961009C7.71842 0.917755 7.46245 0.904447 7.20759 0.90112C6.55112 0.893356 5.89355 0.892247 5.23598 0.90112C4.96456 0.904447 4.68984 0.91221 4.42174 0.973208C4.0113 1.06637 3.81823 1.27487 3.75423 1.69299C3.70017 2.04788 3.69466 2.405 3.69135 2.76323C3.68914 3.01831 3.45965 3.18356 3.22796 3.10038C3.08784 3.05047 2.99736 2.91406 2.99957 2.73772C3.00067 2.52922 3.0084 2.32071 3.01722 2.11221C3.02053 2.03236 3.00288 2.00463 2.91351 2.01018C2.63438 2.03014 2.35414 2.00907 2.0761 2.06341C1.93488 2.09114 1.80579 2.13883 1.69436 2.23642C1.53659 2.37506 1.33358 2.36508 1.20118 2.2209C1.07099 2.07672 1.08313 1.87487 1.23207 1.72626C1.45935 1.50001 1.74732 1.41018 2.05293 1.3636C2.37951 1.31369 2.70719 1.32034 3.03598 1.32145C3.09225 1.32145 3.13418 1.31591 3.15624 1.25158C3.35263 0.668217 3.78402 0.376535 4.36547 0.275611C4.59054 0.236794 4.81672 0.213504 5.0451 0.213504C5.08924 0.213504 5.13558 0.220158 5.1775 0.200195L7.27379 0.200195L7.27489 0.200195Z"
        fill={color}
      />
      <path
        d="M0.799805 3.76798C0.917859 3.57944 1.04474 3.51068 1.20362 3.5495C1.37132 3.59164 1.47062 3.71364 1.47062 3.88665C1.47062 4.51216 1.47503 5.13878 1.47062 5.76428C1.46731 6.19903 1.48386 6.63268 1.49379 7.06743C1.4971 7.21604 1.51916 7.36465 1.55888 7.50994C1.61405 7.70957 1.73762 7.84488 1.93731 7.90255C2.26831 7.99793 2.60813 7.97796 2.94684 7.98351C3.01745 7.98351 3.01635 7.93027 3.01525 7.88037C3.00973 7.67519 3.0009 7.47112 2.9987 7.26595C2.99649 7.10735 3.05828 6.97981 3.20722 6.91105C3.4334 6.8068 3.68937 6.97094 3.68937 7.22602C3.68937 7.46558 3.70481 7.70513 3.71364 7.94469C3.72026 8.11438 3.74453 8.28295 3.78536 8.44931C3.87583 8.82085 4.13621 8.98499 4.49037 9.04377C4.70993 9.08037 4.93059 9.07704 5.15015 9.10366C5.34874 9.12805 5.4679 9.27445 5.45576 9.47852C5.44473 9.65486 5.3024 9.78683 5.11374 9.78573C4.72206 9.78462 4.3326 9.758 3.96299 9.61271C3.57352 9.45855 3.30873 9.17907 3.16861 8.78425C3.13992 8.7044 3.10351 8.67778 3.02076 8.67889C2.6655 8.68221 2.30913 8.68776 1.95717 8.62011C1.30071 8.49478 0.921168 8.08887 0.836214 7.41789C0.824077 7.32029 0.811941 7.22491 0.799805 7.12842C0.799805 6.00828 0.799805 4.88813 0.799805 3.76909L0.799805 3.76798Z"
        fill={color}
      />
      <path
        d="M3.29064 5.36473C3.39877 5.46677 3.50138 5.54994 3.58964 5.64754C3.77831 5.85604 3.68342 6.16769 3.41642 6.22869C3.29506 6.25641 3.18693 6.22092 3.10198 6.13553C2.80409 5.84052 2.50619 5.5444 2.21492 5.24274C2.07701 5.09967 2.07811 4.89782 2.21823 4.75364C2.5073 4.45531 2.80188 4.1603 3.09757 3.86861C3.24541 3.72333 3.45504 3.72887 3.59074 3.86861C3.72314 4.00503 3.72535 4.20909 3.58633 4.3566C3.49696 4.45198 3.39435 4.53405 3.29726 4.62277C3.30388 4.63497 3.31161 4.64828 3.31823 4.66048C3.35795 4.66048 3.39766 4.66048 3.43738 4.66048C4.43257 4.66048 5.42885 4.66048 6.42404 4.66048C6.63367 4.66048 6.7771 4.79135 6.78261 4.98322C6.78923 5.19837 6.64911 5.34255 6.42955 5.34255C5.42996 5.34255 4.43146 5.34255 3.43187 5.34255C3.39546 5.34255 3.35684 5.33368 3.28954 5.36695L3.29064 5.36473Z"
        fill={color}
      />
    </svg>
  )
}
