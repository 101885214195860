import React from 'react'
import {
  useActions,
  useEffect,
  usePremiumCardSubscriptionUpsale,
  useSelector,
  useState,
} from 'src/hooks'
import { useFormatSOCMediaUrl } from 'src/hooks/useFormatSOCMediaUrl'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import {
  useCheckout,
  useDeleteCheckoutLines,
  usePlans,
  useProductVariant,
  useRemoveUserUpsell,
} from 'src/react_query'
import { SubscriptionCheckout } from 'src/saleor/components/subscription_checkout/SubscriptionCheckout'
import { FullPageUpsale } from '../FullPageUpsale/FullPageUpsale'
import DynamicSubSelectorDialog from 'src/SubscriptionChanger/components/DynamicSubSelectorDialog'
import { PlanFragment } from 'src/graphql/generated/graphql'
import { DialogUpsaleCheckout } from 'src/upsale/components/checkout/DialogUpsaleCheckout'
import { checkIsInCart } from 'src/pricing_page/helpers/helpers'
import { getPlanDescriptions } from 'src/saleor/utils/getPlanDescriptions'
import BasicToaster from 'src/SubscriptionChanger/components/BasicToaster'
import BasicTransition from 'src/SubscriptionChanger/components/BasicTransition'

type PremiumCardUpsaleProps = {}

export const PremiumCardUpsale: React.FC<PremiumCardUpsaleProps> = props => {
  const {
    shouldShowUpsell,
    plan,
    plans,
    title,
    description,
    features,
    upsellSlug,
    upsaleFullImage,
    continueButtonTitle,
  } = usePremiumCardSubscriptionUpsale()
  const actions = useActions()
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false)
  const [isViewAllPlansOpen, setIsViewAllPlansOpen] = useState(false)
  const image = useFormatSOCMediaUrl(upsaleFullImage ?? 'casual-sender')
  const [selectedOption, setSelectedOption] = useState(
    plans?.[0].optionId ?? '',
  )
  const { data: checkout } = useCheckout()
  const {
    addToCart,
    hasCheckoutError,
    checkoutErrorMessage,
    isCreatingCheckout,
    isUpdatingCheckout,
  } = useHandleProductActions(plan?.stripeId)
  const removeUserUpsell = useRemoveUserUpsell()

  const plansQuery = usePlans({ suspense: true })
  const { mutate: deleteCheckoutLines } = useDeleteCheckoutLines()
  const plansArr = plansQuery.data!

  const {
    user: { account },
  } = useSelector(state => state)
  const [selPlan, setSelPlan] = useState<PlanFragment | undefined>(undefined)

  const currentPlan = account?.plan
  const shouldHideCurrentPlan = false // Came as prop in another comp
  const [stagedPlan, setStagedPlan] = useState<PlanFragment | undefined>(
    shouldHideCurrentPlan ? undefined : currentPlan,
  )
  const [selectedSku, setSelectedSku] = useState(stagedPlan?.stripeId ?? '')

  const { data } = useProductVariant({ sku: stagedPlan?.stripeId ?? '' })
  const saleorPlanDescriptions = getPlanDescriptions(plansArr)
  const variantIdOnPlan = stagedPlan
    ? saleorPlanDescriptions[stagedPlan?.id]?.saleorVariantId
    : ''
  const variantId = variantIdOnPlan ?? data?.id
  const [selectedOptionVariantId, setSelectedOptionVariantId] = useState<
    string | undefined
  >(stagedPlan?.relatedPackage?.saleorProductId ?? variantId)

  const handleClose = async () => {
    actions.resetUpsellState()
    if (upsellSlug) {
      await removeUserUpsell.mutateAsync({ slug: upsellSlug })
    }
  }

  const handleStageSelectedOption = (optionId: string, sku?: string | null) => {
    setSelectedOption(optionId)
    setSelectedOptionVariantId(optionId)
    if (!sku) return
    setSelectedSku(sku)
  }

  const handleUnlock = () => {
    const isOnCart = checkIsInCart(checkout, selectedSku)
    if (isOnCart || currentPlan?.id === stagedPlan?.id) return

    const hasNonBasicPlan = currentPlan && currentPlan.id !== '1'
    if (hasNonBasicPlan && stagedPlan?.stripeId === selectedSku) {
      setSelPlan(stagedPlan)
    } else {
      addToCart(selectedOptionVariantId)
      setIsCheckoutOpen(true)
    }
  }

  const handleCloseCheckout = (shouldKeepCheckoutOpen: boolean) => {
    if (!shouldKeepCheckoutOpen && checkout) {
      deleteCheckoutLines({ linesIds: checkout?.lines.map(line => line.id) })
    }
    setIsCheckoutOpen(shouldKeepCheckoutOpen)
  }

  useEffect(() => {
    if (plan) {
      setStagedPlan(plan)
      setSelectedSku(plan.stripeId)
      setSelectedOptionVariantId(
        saleorPlanDescriptions[plan?.id]?.saleorVariantId,
      )
      setSelectedOption(plan.id ?? '')
    }
  }, [plan, saleorPlanDescriptions])

  return (
    <>
      <FullPageUpsale
        isOpen={!!shouldShowUpsell}
        upsaleImage={image}
        plan={{
          title: title ?? '',
          description: description ?? '',
          planOptions: {
            options: plans ?? [],
            selectedOption: selectedOption,
            onSelectOption: handleStageSelectedOption,
          },
          planFeatureList: { features: features },
        }}
        onShowTerms={() => console.log('show terms')}
        onViewAll={() => setIsViewAllPlansOpen(true)}
        onClose={handleClose}
        primaryAction={{
          title: 'Select Plan',
          onClick: handleUnlock,
        }}
        secondaryAction={
          continueButtonTitle
            ? {
                title: continueButtonTitle,
                onClick: handleClose,
              }
            : undefined
        }
      />
      {hasCheckoutError && checkoutErrorMessage && (
        <BasicToaster message={checkoutErrorMessage} />
      )}
      <BasicTransition
        shouldDisplay={isUpdatingCheckout || isCreatingCheckout}
        message="Creating checkout..."
      />

      <DynamicSubSelectorDialog
        isOpen={isViewAllPlansOpen}
        onClose={() => setIsViewAllPlansOpen(false)}
        planIds={[{ id: '189' }, { id: '190' }, { id: '191' }, { id: '184' }]}
      />

      {selPlan && (
        <DialogUpsaleCheckout
          selectedItem={selPlan}
          onClose={() => {
            setSelPlan(undefined)
            handleCloseCheckout(false)
          }}
        />
      )}
      {checkout && isCheckoutOpen && (
        <SubscriptionCheckout
          shouldSkipRedirect={true}
          isOpen={isCheckoutOpen}
          setIsOpen={handleCloseCheckout}
        />
      )}
    </>
  )
}
