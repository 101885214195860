import { Reducer } from 'redux'
import combineSectionReducers from 'combine-section-reducers'
import { StateType } from '@sendoutcards/core'

import { logout } from 'src/redux/actions/user'

import Action from 'src/redux/action'
import { windowReducer } from './window'
// reducers
import route from './routes'
import context from './context'
import helpmenu from './helpmenu'
import orders from './orders'
import graphql from './graphql'
import user from './user'
import act from './act'
import upsell from './upsell'

import { BaseRoute } from 'src/routes/BaseRoute'
import { DashboardRoute } from 'src/dashboard/routes/DashboardRoute'
import { AccountRoute } from 'src/dashboard/routes/AccountRoute'

const reducers = {
  context,
  helpmenu,
  orders,
  graphql,
  user,
  window: windowReducer,
  act,
  upsell,
}

export type StateWithoutRoute = {
  [K in keyof typeof reducers]: ReturnType<(typeof reducers)[K]>
}

const combinedReducer: Reducer<
  StateWithoutRoute & { route: ReturnType<typeof route> },
  Action
> = combineSectionReducers({ route, ...reducers })

export interface State extends StateType<typeof combinedReducer> {}

export const appReducer: typeof combinedReducer = (state, action) =>
  action.type === logout.type && state
    ? combinedReducer(
        {
          user: state.user,
          route: {
            ...BaseRoute(DashboardRoute(AccountRoute())),
            args: state.route.args,
          },
        } as State,
        action,
      )
    : combinedReducer(state, action)
