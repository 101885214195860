import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { Button } from 'src/design_system/components/Button/Button'
import { PlanFragment } from 'src/graphql/generated/graphql'
import { useProductVariant } from 'src/react_query/queries/hooks'

type UnlockButtonProps = {
  plan?: PlanFragment
  selectedSku?: string
  isDisabled: boolean
  handleClick: () => void
}

const UnlockButton: React.FC<UnlockButtonProps> = props => {
  const { plan, selectedSku, isDisabled, handleClick } = props

  const hasOptions = !!plan?.relatedPackage
  const { data: relatedPlan } = useProductVariant(
    { id: plan?.relatedPackage?.saleorProductId },
    hasOptions,
  )

  const relatedPlanPrice = relatedPlan?.pricing?.price?.gross?.amount.toFixed(2)
  const planPrice = plan?.price.description

  const isRelatedPlanSelected = plan?.stripeId !== selectedSku

  return (
    <Button
      width="100%"
      isDisabled={isDisabled}
      onClick={handleClick}
      backgroundColor="#404040"
      textColor="#f5f6f7"
    >
      <div style={{ marginRight: '4px' }}>
        <Icon name={'premium_crown'} color="#FECD39" />
      </div>
      {plan && plan.id !== '1'
        ? `Unlock ${isRelatedPlanSelected ? `for: $${relatedPlanPrice}` : `${planPrice ? `for: ${planPrice}` : ``}`}`
        : 'Unlock'}
    </Button>
  )
}

export default UnlockButton
