import React from 'react'
import { Set } from 'immutable'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { Button, Confirm, DefaultError, Search, Transition } from 'src/chrome'
import { CatalogRibbon } from '..'
import { useActions, useState } from 'src/hooks'
import styles from './cardCategory.module.scss'

import { Api } from '../../api'
import {
  useBulkDeleteCards,
  useBulkDeleteSendableCards,
  useFavoriteSendableCard,
  useGetCardCategories,
  useGetCardCategory,
  useGetCustomCards,
  useGetLegacyPicturePlusCards,
  useGetSendableCards,
  useUnfavoriteSendableCard,
} from 'src/react_query'
import {
  CardCategoryFragment,
  GetSendableCardsQueryVariables,
} from 'src/graphql/generated/graphql'

type DisplayedCardsQuery =
  | {
      type: 'custom'
    }
  | {
      type: 'legacy-picture-plus'
    }
  | {
      type: 'sendable'
      variables: Omit<GetSendableCardsQueryVariables, 'offset' | 'limit'>
    }

interface Props {
  api: Api
  displayedCards:
    | {
        type: 'category'
        id: string
      }
    | { type: 'premium' }
    | { type: 'favorites' }
    | { type: 'custom' }
    | { type: 'legacy-picture-plus' }
    | { type: 'catalog-card' }
  hasContextBar: boolean
}

const CardCategory: React.FC<Props> = props => {
  const { displayedCards, api } = props

  const actions = useActions()
  const bulkDeleteCards = useBulkDeleteCards()
  const bulkDeleteSendableCards = useBulkDeleteSendableCards()
  const favoriteSendableCard = useFavoriteSendableCard()
  const unfavoriteSendableCard = useUnfavoriteSendableCard()

  const { data: cardCategories } = useGetCardCategories({
    enabled:
      displayedCards.type === 'custom' ||
      displayedCards.type === 'legacy-picture-plus',
  })

  const { data: cardCategory } = useGetCardCategory({
    id: displayedCards.type === 'category' ? displayedCards.id : undefined,
  })

  const [search, setSearch] = useState('')
  const [selectedCardsToDelete, setSelectedCardsToDelete] = useState(Set())
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {
    query: displayedCardsQuery,
    showSearch,
    canDelete,
    canFavorite,
  } = ((): {
    query: DisplayedCardsQuery
    showSearch: boolean
    canDelete: boolean
    canFavorite: boolean
  } => {
    switch (displayedCards.type) {
      case 'custom':
        return {
          query: {
            type: 'custom',
          },
          showSearch: false,
          canDelete: true,
          canFavorite: false,
        }
      case 'legacy-picture-plus':
        return {
          query: {
            type: 'legacy-picture-plus',
          },
          showSearch: false,
          canDelete: true,
          canFavorite: false,
        }
      case 'favorites':
        return {
          query: {
            type: 'sendable',
            variables: {
              search: search,
              favorite: true,
            },
          },
          showSearch: false,
          canDelete: false,
          canFavorite: true,
        }
      case 'premium':
        return {
          query: {
            type: 'sendable',
            variables: {
              search: search,
              isPremium: true,
            },
          },
          showSearch: false,
          canDelete: false,
          canFavorite: true,
        }
      case 'category':
        return {
          query: {
            type: 'sendable',
            variables: {
              search: search,
              category: displayedCards.id,
            },
          },
          showSearch: false,
          canDelete: false,
          canFavorite: true,
        }
      case 'catalog-card':
        return {
          query: {
            type: 'sendable',
            variables: {
              search: search,
            },
          },
          showSearch: true,
          canDelete: false,
          canFavorite: true,
        }
    }
  })()

  const customCards = useGetCustomCards({
    enabled: displayedCardsQuery.type === 'custom',
  })
  const legacyPicturePlusCards = useGetLegacyPicturePlusCards({
    enabled: displayedCardsQuery.type === 'legacy-picture-plus',
  })
  const sendableCards = useGetSendableCards(
    displayedCardsQuery.type === 'sendable'
      ? displayedCardsQuery.variables
      : {},
    { enabled: displayedCardsQuery.type === 'sendable' },
  )

  const fetchedCards =
    displayedCardsQuery.type === 'custom'
      ? customCards
      : displayedCardsQuery.type === 'legacy-picture-plus'
        ? legacyPicturePlusCards
        : sendableCards

  // const headerTitle = (() => {
  //   switch (displayedCards.type) {
  //     case 'category':
  //       return {
  //         title: category?.description ?? '',
  //       }
  //     case 'premium':
  //       return {
  //         title: 'Photo Drop Cards',
  //         description:
  //           'Put your own image in these cards and add the perfect personal touch.',
  //       }
  //     case 'catalog-card':
  //       return {
  //         title: 'Catalog Cards',
  //         description:
  //           'Browse our card catalog for quality designs to send to your loved ones.',
  //       }
  //     case 'custom':
  //       return {
  //         title: '',
  //       }
  //     case 'favorites':
  //       return {
  //         title: 'My Favorites',
  //       }
  //     default:
  //       const firstCard = fetchedCards.results[0]
  //       const title = isMinimalSendableCardFragment(firstCard)
  //         ? firstCard.title
  //         : ''
  //       return {
  //         title,
  //       }
  //   }
  // })()

  const handleOnSearch = (value: string) => setSearch(value)

  const handleClearSearch = () => setSearch('')

  const handleOnClose = () => actions.dismissedCatalogSubroute()

  const onSelectCategory = (cardCategory: CardCategoryFragment) => {
    actions.selectedCardCategory(cardCategory.id)
  }

  const handleOpenCardPreview = (id: string, typename?: string) => {
    if (typename === 'Card') {
      actions.selectedCatalogCard(typename, id)
    } else {
      actions.selectedCatalogCard('SendableCard', id)
    }
  }

  const handleFavoriteCard = async (id: string, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await unfavoriteSendableCard.mutateAsync({ id })
      } else {
        await favoriteSendableCard.mutateAsync({ id })
      }
    } catch (error) {
      console.error('Failed to favorite/unfavorite card.')
    }
  }

  const selectMyCard = (sendableCardId: string) =>
    setSelectedCardsToDelete(
      selectedCardsToDelete.has(sendableCardId)
        ? selectedCardsToDelete.delete(sendableCardId)
        : selectedCardsToDelete.add(sendableCardId),
    )

  const deleteSelectedCustomCards = () => {
    switch (displayedCards.type) {
      case 'custom':
        bulkDeleteCards.mutate({ cards: Array.from(selectedCardsToDelete) })
        break
      case 'legacy-picture-plus':
      case 'favorites':
      case 'premium':
      case 'category':
        bulkDeleteSendableCards.mutate({
          cards: Array.from(selectedCardsToDelete),
        })
        break
    }
    setSelectedCardsToDelete(Set())
    toggleDeleteModal()
  }

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen)

  const loadingMessage = fetchedCards.isLoading
    ? 'Loading cards...'
    : bulkDeleteCards.isLoading || bulkDeleteSendableCards.isLoading
      ? 'Deleting cards...'
      : favoriteSendableCard.isLoading
        ? 'Favoriting card...'
        : unfavoriteSendableCard.isLoading
          ? 'Unfavoriting card...'
          : null
  const transition = loadingMessage ? (
    <Transition message={loadingMessage} />
  ) : undefined

  const fetchedCardsData = fetchedCards.data?.pages.flat() ?? []

  return (
    <div>
      {showSearch && (
        <Search
          categories={cardCategories}
          activeCategory={cardCategory}
          handleCategory={onSelectCategory}
          autocomplete={'off'}
          buttonClick={() => api.setShowBuildYourOwnModal(true)}
          onSearch={handleOnSearch}
          clearSearch={handleClearSearch}
          className={styles.catalogSearchBar}
          inputClassName={styles.catalogSearchField}
          tagsClassName={styles.catalogSearchTags}
        />
      )}
      {canDelete && selectedCardsToDelete.size > 0 && (
        <Button
          title={'Delete'}
          buttonColor={'pink'}
          style={{ width: 126, marginTop: 35 }}
          className={styles.deleteButton}
          onClick={toggleDeleteModal}
        />
      )}
      <CatalogRibbon
        type={'search_results'}
        canFavorite={canFavorite}
        title={cardCategory?.description}
        items={fetchedCardsData}
        isSearchResultEmpty={
          search !== '' &&
          fetchedCards.hasNextPage &&
          fetchedCardsData.length <= 0
        }
        search={search}
        category={cardCategory?.description}
        isWrapContentEnabled={true}
        onClick={handleOpenCardPreview}
        onClose={handleOnClose}
        handleSearchAll={handleClearSearch}
        handleCheckbox={canDelete ? selectMyCard : undefined}
        selectedSet={selectedCardsToDelete}
        isLoadingMore={fetchedCards.isFetchingNextPage}
        hasMore={fetchedCards.hasNextPage}
        loadMore={
          fetchedCards.hasNextPage ? fetchedCards.fetchNextPage : undefined
        }
        handleFavoriteCard={handleFavoriteCard}
      />
      {isDeleteModalOpen && (
        <Confirm
          title={`Delete ${selectedCardsToDelete.size} Cards`}
          message={'Are you sure you want to delete the selected card(s)?'}
          confirmTitle={'Delete'}
          onConfirm={deleteSelectedCustomCards}
          onDecline={toggleDeleteModal}
        />
      )}
      {transition}
    </div>
  )
}

export default suspenseBoundary({
  component: CardCategory,
  unresolved: <Transition />,
  failure: DefaultError,
})
