import React from 'react'
import { PlanFragment } from 'src/graphql/generated/graphql'
import { useSelector } from 'src/hooks'
import { useProductVariant } from 'src/react_query/queries/hooks'
import { SubscriptionSelectionTile } from './SubscriptionSelectionTile'
import { FeatureType } from 'src/pricing_page/hooks/usePlanIncludes'
import { Flex } from '@sendoutcards/quantum-design-ui'
import { FeatureIncludesList } from 'src/pricing_page/components/pricingTile/components/Tile/FeatureIncludesList'
import {
  OptionType,
  SelectableOptions,
} from 'src/upsale/components/pricingTile/components/SelectableOptions'
import { Checkout } from 'src/saleor_graphql/generated/graphql'
import { checkIsInCart } from 'src/pricing_page/helpers/helpers'

type DynamicSubscriptionSelectionTileProps = {
  plan: PlanFragment
  isDowngraded: boolean
  isSelected: boolean
  onChange: (plan: PlanFragment) => void
  checkout?: Checkout
  features?: FeatureType[]
  variantIdOnPlan?: string
  selectedOptionVariantId?: string
  handleStageSelectedOption?: (optionId: string, sku?: string | null) => void
}

const DynamicSubscriptionSelectionTile: React.FC<
  DynamicSubscriptionSelectionTileProps
> = ({
  plan,
  isDowngraded,
  isSelected,
  features,
  onChange,
  checkout,
  variantIdOnPlan,
  selectedOptionVariantId,
  handleStageSelectedOption,
}) => {
  const {
    user: { account },
  } = useSelector(state => state)
  const currentPlan = account?.plan

  const hasOptions = !!plan.relatedPackage
  const { data: relatedPlan } = useProductVariant(
    { id: plan.relatedPackage?.saleorProductId },
    hasOptions,
  )

  if (!plan?.description || plan.id === currentPlan?.id) return null

  const description = JSON.parse(plan.description)
  const isCurrentPlan = currentPlan?.id === plan.id
  const isPreviousPlan = isDowngraded && isCurrentPlan
  const planPillData = description.planPillData

  const selectablePlanOptionItems =
    hasOptions && relatedPlan
      ? ([
          {
            title: 'One-time Purchase',
            price: `$${relatedPlan?.pricing?.price?.gross?.amount.toFixed(2)}`,
            optionId: relatedPlan?.id,
            isMonthly: false,
            disabledReason:
              checkIsInCart(checkout, relatedPlan?.sku) && 'added to cart',
          },
          {
            isMonthly: true,
            title: 'Subscribe & Save',
            price: plan.price.description,
            optionId: variantIdOnPlan,
            disabledReason: isCurrentPlan
              ? 'current plan'
              : checkIsInCart(checkout, plan.stripeId)
                ? 'added to cart'
                : undefined,
          },
        ] as OptionType[])
      : undefined

  return (
    <div>
      <SubscriptionSelectionTile
        children={
          features &&
          features?.length > 0 && (
            <Flex
              width="100%"
              outset={{ top: 'x2' }}
              borderRadius="8px"
              inset={{ vertical: 'x1', horizontal: 'x2' }}
              style={{
                backgroundColor: '#f5f6f7',
                flex: 1,
                alignItems: 'stretch',
              }}
              flexDirection="column"
            >
              <FeatureIncludesList
                features={features}
                includedIconColor="#be9cdd"
              />
              {selectablePlanOptionItems && (
                <SelectableOptions
                  customGradient={[
                    plan.backgroundStartColor,
                    plan.backgroundEndColor,
                  ]}
                  customBgColor="#FFF"
                  options={selectablePlanOptionItems}
                  selectedOption={selectedOptionVariantId ?? variantIdOnPlan}
                  onSelectOption={(optionId: string) => {
                    handleStageSelectedOption?.(
                      optionId,
                      relatedPlan?.id && optionId === relatedPlan?.id
                        ? relatedPlan?.sku
                        : plan.stripeId,
                    )
                  }}
                />
              )}
            </Flex>
          )
        }
        key={plan.id}
        plan={plan}
        onSelect={() => {
          onChange(plan)
        }}
        isSelected={isSelected}
        isPreviousPlan={isPreviousPlan}
        pill={
          isCurrentPlan
            ? {
                text: 'Current',
                backgroundColor: '#6B7280',
                textColor: '#FFFFFF',
              }
            : planPillData && {
                text: planPillData.text,
                backgroundColor: planPillData.backgroundColor,
                textColor: planPillData.textColor,
              }
        }
        indicatorBackground={description.indicatorBackground}
      />
    </div>
  )
}

export default DynamicSubscriptionSelectionTile
