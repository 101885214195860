import { usePlanFeatures } from 'src/pricing_page/hooks/usePlanIncludes'
import { usePlanUpsaleInfo } from 'src/pricing_page/hooks/usePlanUpsaleInfo'
import { usePlans, useProductVariant } from 'src/react_query'
import { usePlanManagement } from 'src/saleor/utils/usePlanManagement'

const usePlanInfo = (planId?: string) => {
  const { data: plans } = usePlans({ suspense: true })
  if (!plans) {
    throw new Error("Missing plans' data")
  }

  const plan = plans.find(plan => plan.id === planId)

  const hasOptions = !!plan?.relatedPackage
  const { data: relatedPlan } = useProductVariant(
    { id: plan?.relatedPackage?.saleorProductId },
    hasOptions,
  )

  const { planDescriptions } = usePlanManagement([
    { id: plan?.id ?? plans[0].id },
  ])

  const features = usePlanFeatures(planDescriptions[plan?.id ?? '1'])
  const planUpsaleInfo = usePlanUpsaleInfo(planDescriptions[plan?.id ?? '1'])

  const { data: variant } = useProductVariant({ sku: plan?.stripeId ?? '' })
  const saleorVariantId =
    variant?.id ?? planDescriptions[plan?.id ?? '1'].saleorVariantId

  const upsaleInfo = {
    fullUpsale: planUpsaleInfo.fullUpsale,
    miniUpsale: planUpsaleInfo.miniUpsale,
  }

  const formattedPlan = {
    title: plan?.title ?? '',
    price: plan?.price.description ?? '',
    optionId: plan?.id ?? '',
    sku: plan?.stripeId ?? '',
  }

  const formattedRelatedPlan = {
    title: 'One-time Purchase' ?? '',
    price: relatedPlan?.pricing?.price?.gross?.amount.toFixed(2) ?? '',
    optionId: relatedPlan?.id ?? '',
    sku: relatedPlan?.sku ?? '',
  }

  return {
    plan,
    features,
    variant,
    saleorVariantId,
    formattedPlan,
    formattedRelatedPlan,
    planDescriptions,
    upsaleInfo,
  }
}

export default usePlanInfo
