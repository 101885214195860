import { useAccount } from 'src/hooks'
import { useUserCardTokens } from 'src/react_query'
import { StatusType } from '../components/StatusPill'
import { accountSettingViews } from '../DashboardSplashScreen'
import { useActions } from 'src/hooks'
export const useAccountSettings = (
  setActiveSettingView: (view: keyof typeof accountSettingViews) => void,
  setShouldShowProfileSettings: (isShown: boolean) => void,
) => {
  const account = useAccount()
  const userCardTokens = useUserCardTokens()
  const actions = useActions()
  const actTokenCount = userCardTokens.data?.actSends ?? 0
  const physicalCardTokenCount = userCardTokens.data?.cardSends ?? 0
  const isExclusiveOfferActive = account.plan.id === '1'

  const sendCardSettingsCustomTitles = [
    isExclusiveOfferActive && actTokenCount > 0
      ? `Free Act: ${actTokenCount}`
      : null,
    isExclusiveOfferActive && physicalCardTokenCount > 0
      ? `Free Printed: ${physicalCardTokenCount}`
      : null,
  ].filter(Boolean) as string[]

  console.log(sendCardSettingsCustomTitles)

  return [
    // // TODO: Fix Exclusive Offer Card design to support card counts and time remaining
    // {
    //   title: 'Exclusive Offer',
    //   description:
    //     'Your offer ends in 2 days, make sure to send your 1 printed and 3 digital acts before time runs out.',
    //   status: 'custom' as StatusType,
    //   customStatusTitles:
    //     sendCardSettingsCustomTitles.length > 0
    //       ? sendCardSettingsCustomTitles
    //       : undefined,
    //   onClick: () => alert('Click Exclusive Offer'),
    // },
    {
      title: 'Send a Card',
      description:
        'ACT on a Prompting. Send a card to someone that needs to hear from you.',
      onClick: () => actions.openCatalog(),
    },
    {
      title: 'Address',
      description: `${account.hasAddress ? 'Edit' : 'Add'} or edit your address.`,
      status: account.hasAddress ? undefined : ('missing' as StatusType),
      onClick: () => setActiveSettingView('addressView'),
    },
    {
      title: 'Payment Method',
      description: `${account.hasPaymentMethod ? 'Edit' : 'Add'} payment method for cards and subscriptions.`,
      status: account.hasPaymentMethod ? undefined : ('missing' as StatusType),
      onClick: () => setActiveSettingView('paymentView'),
    },
    {
      title: 'Profile',
      description:
        'Edit your name, email, password, phone number, & message preferences.',
      status: account.isProfileComplete
        ? undefined
        : ('incomplete' as StatusType),
      onClick: () => setShouldShowProfileSettings(true),
    },
    {
      title: 'Card History',
      description: 'View your recent physical and digital card orders.',
      status: undefined,
      onClick: () => actions.openCardHistory(),
    },
    {
      title: 'Manage Membership',
      description:
        'Explore benefits and get the most out of the Promptings Gratitude Sharing System.',
      status: undefined,
      onClick: () => actions.openPaymentSettings(),
    },
  ]
}
