import React from 'react'
import { Transition } from 'src/chrome'
import { Portal } from 'src/portal/portal'

type BasicTransitionProps = {
  shouldDisplay: boolean
  message: string
}

const BasicTransition: React.FC<BasicTransitionProps> = props => {
  const { shouldDisplay, message } = props

  return shouldDisplay ? (
    <Portal>
      <Transition message={message} />
    </Portal>
  ) : (
    <></>
  )
}

export default BasicTransition
