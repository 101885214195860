import { useMemo } from 'src/hooks'

type UpsaleDisplayType = {
  title: string
  description: string
  image: string
}

type UpsaleInfoType = {
  fullUpsale: UpsaleDisplayType
  miniUpsale: UpsaleDisplayType
}

export const usePlanUpsaleInfo = (description?: {
  upsaleInfo?: UpsaleInfoType
}) => {
  const upsale = description?.upsaleInfo

  return useMemo<UpsaleInfoType>(
    () => ({
      fullUpsale: {
        title: upsale?.fullUpsale?.title ?? '',
        description: upsale?.fullUpsale?.description ?? '',
        image: upsale?.fullUpsale?.image ?? '',
      },
      miniUpsale: {
        title: upsale?.miniUpsale?.title ?? '',
        description: upsale?.miniUpsale?.description ?? '',
        image: upsale?.miniUpsale?.image ?? '',
      },
    }),
    [upsale],
  )
}
