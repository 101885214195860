import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { useAccount, useSelector, useState } from 'src/hooks'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { AddressDisplayCard } from './components/AddressDisplayCard'

import { emptyShippingAddress } from 'src/app/constants'

import { ReturnAddressForm } from './components/ReturnAddressForm'

type AddressViewType = {
  onClose: () => void
}
export const AddressView = ({ onClose }: AddressViewType) => {
  const [shouldShowAddressView, setShouldShowAddressView] = useState(true)
  const [isEditingAddress, setIsEditingAddress] = useState(false)

  const account = useAccount()
  const isMobile = useSelector(state => state.window.isMobile)

  const { firstName, lastName, address1, city, state, postalCode } =
    account.shippingAddress ?? emptyShippingAddress
  const displayAddress = {
    firstName,
    lastName,
    address1,
    city,
    state,
    postalCode,
  }

  const handleClose = () => {
    setShouldShowAddressView(false)
    onClose()
  }

  return (
    <Dialog
      padding={isMobile ? '72px 16px' : '12px 24px 12px 24px'}
      title={isEditingAddress ? 'Edit Return Address' : 'Return Address'}
      isOpen={shouldShowAddressView}
      onClose={handleClose}
      mobileOpenHeight={
        isEditingAddress ? 'calc(100dvh - 700px)' : 'calc(100dvh - 300px)'
      }
    >
      <Flex id="account_address_form_container" flexDirection="column">
        <Text
          type="caption"
          content={
            isEditingAddress
              ? 'Edit your return address for physical card sending'
              : 'Return address for your printed cards'
          }
          inset={{ bottom: '20px' }}
        />
        {isEditingAddress ? (
          <ReturnAddressForm />
        ) : (
          <AddressDisplayCard
            onEdit={() => setIsEditingAddress(true)}
            address={displayAddress}
          />
        )}
      </Flex>
    </Dialog>
  )
}
