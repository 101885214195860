import { Div } from '@sendoutcards/quantum-design-ui'
import {
  HOCSpacingKeys,
  LiteralUnion,
  SpacingDirection,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type LogoProps = {
  color?: string
  secondaryColor?: string
  size?: number
}

type UpsaleHeaderProps = {
  logo?: LogoProps
  onClose: () => void
  inset?: SpacingDirection | LiteralUnion<HOCSpacingKeys>
}

export const UpsaleHeader = (props: UpsaleHeaderProps) => {
  const {
    onClose,
    logo = { color: '#fff', secondaryColor: '#fff', size: 130 },
    inset,
  } = props

  const closeBtnStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9FAFB',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
  }
  return (
    <Div
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      inset={inset}
    >
      <Icon
        name={'promptings_powered_by_soc_logo'}
        color={logo.color}
        secondaryColor={logo.secondaryColor}
        size={logo.size}
      />
      <Div onClick={onClose} style={{ ...closeBtnStyles }}>
        <Icon name="x" size={10} />
      </Div>
    </Div>
  )
}
