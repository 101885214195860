import { useForm, Controller } from 'react-hook-form'
import { AddressInput } from 'src/graphql/generated/graphql'
import {
  Flex,
  Div,
  Text,
  Input,
  LoadingSpinner,
  Select,
} from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import { useAccount, useSelector } from 'src/hooks'
import { useUpdateAccount } from 'src/react_query'
import { emptyShippingAddress } from 'src/app/constants'
import useCountryAndRegions from 'src/hooks/useCountryAndRegions'

const omitTypename = <T extends Record<string, unknown>>(
  obj: T,
): Omit<T, '__typename'> => {
  const { __typename, ...rest } = obj
  return rest as Omit<T, '__typename'>
}

export const ReturnAddressForm = () => {
  const account = useAccount()
  const { mutate, isLoading } = useUpdateAccount()
  const isMobile = useSelector(state => state.window.isMobile)

  const defaultValues = account.shippingAddress
    ? omitTypename(account.shippingAddress)
    : emptyShippingAddress

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressInput>({ defaultValues })

  const { generatedStateOptions, generatedCountryOptions } =
    useCountryAndRegions(
      account?.shippingAddress?.country ?? emptyShippingAddress.country,
    )

  return (
    <form
      onSubmit={handleSubmit(data => {
        mutate({ account: { shippingAddress: omitTypename(data) } })
      })}
    >
      <Flex
        width="100%"
        backgroundColor="foreground"
        boxShadow="0px 4px 12px 0px rgba(0, 0, 0, 0.04)"
        rowGap={'x1_5'}
        columnGap={'x1'}
        style={{
          borderRadius: 20,
          padding: 20,
          flexDirection: 'column',
        }}
      >
        <Text type="largeBody" color="primaryHeading">
          Return Address
        </Text>

        <Flex columnGap="x1">
          <Div width={isMobile ? '100%' : '50%'}>
            <Controller
              control={control}
              name="firstName"
              rules={{ required: 'First Name is required' }}
              render={({ field }) => (
                <Input
                  isFullWidth
                  type="text"
                  placeholder="First Name"
                  {...field}
                  focusColor={errors.firstName ? 'danger' : undefined}
                  message={
                    errors.firstName
                      ? { type: 'danger', content: errors.firstName.message! }
                      : undefined
                  }
                />
              )}
            />
          </Div>
          <Div width={isMobile ? '100%' : '50%'}>
            <Controller
              control={control}
              name="lastName"
              rules={{ required: 'Last Name is required' }}
              render={({ field }) => (
                <Input
                  isFullWidth
                  type="text"
                  placeholder="Last Name"
                  {...field}
                  focusColor={errors.lastName ? 'danger' : undefined}
                  message={
                    errors.lastName
                      ? { type: 'danger', content: errors.lastName.message! }
                      : undefined
                  }
                />
              )}
            />
          </Div>
        </Flex>

        {/** Address */}
        <Flex
          columnGap={'x2'}
          rowGap="x1_5"
          width="100%"
          flexDirection="column"
        >
          <Controller
            control={control}
            name="address1"
            rules={{ required: 'Address is required' }}
            render={({ field }) => (
              <Input
                type="text"
                isFullWidth
                placeholder="Address Line 1"
                {...field}
                focusColor={errors.address1 ? 'danger' : undefined}
                message={
                  errors.address1
                    ? { type: 'danger', content: errors.address1.message! }
                    : undefined
                }
              />
            )}
          />
          <Controller
            control={control}
            name="address2"
            render={({ field }) => (
              <Input
                type="text"
                isFullWidth
                placeholder="Address Line 2"
                {...field}
                focusColor={errors.address2 ? 'danger' : undefined}
                message={
                  errors.address2
                    ? { type: 'danger', content: errors.address2.message! }
                    : undefined
                }
              />
            )}
          />
        </Flex>

        {/** City, State, Postal */}
        <Flex
          rowGap="x2"
          columnGap="x2"
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          <Div id="city_field" width={'100%'}>
            <Controller
              control={control}
              name="city"
              rules={{ required: 'City is required' }}
              render={({ field }) => (
                <Input
                  isFullWidth
                  type="text"
                  placeholder="City"
                  {...field}
                  focusColor={errors.city ? 'danger' : undefined}
                  message={
                    errors.city
                      ? { type: 'danger', content: errors.city.message! }
                      : undefined
                  }
                />
              )}
            />
          </Div>
          <Div id="state_field" width={isMobile ? '120px' : '200px'}>
            <Controller
              control={control}
              name="state"
              rules={{ required: 'State is required' }}
              render={({ field }) => (
                <Select
                  selectWidth="100%"
                  selectedOptionIndex={generatedStateOptions.findIndex(
                    option => option.value === field.value,
                  )}
                  onChange={selectedIndex =>
                    field.onChange(
                      generatedStateOptions[selectedIndex]?.value || '',
                    )
                  }
                  alignment="center"
                  options={generatedStateOptions}
                  type="text"
                  dropDownMaxHeight="250px"
                  isDropDownAbove
                  focusColor={errors.state ? 'danger' : undefined}
                  message={
                    errors.state
                      ? { type: 'danger', content: errors.state.message! }
                      : undefined
                  }
                />
              )}
            />
          </Div>

          {/** Postal Code */}
          <Div
            id="postal_code_field"
            width={isMobile ? 'calc(100% - 140px)' : '100%'}
          >
            <Controller
              control={control}
              name="postalCode"
              rules={{
                required: 'Postal Code is required',
                pattern: {
                  value: /^[0-9a-zA-Z\s-]+$/,
                  message: 'Invalid Postal Code',
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  isFullWidth
                  placeholder="Postal Code"
                  focusColor={errors.postalCode ? 'danger' : undefined}
                  message={
                    errors.postalCode
                      ? { type: 'danger', content: errors.postalCode.message! }
                      : undefined
                  }
                />
              )}
            />
          </Div>
        </Flex>

        {/** Country */}
        <Div id="country_field">
          <Controller
            control={control}
            name="country"
            rules={{ required: 'Country is required' }}
            render={({ field }) => (
              <Select
                selectedOptionIndex={generatedCountryOptions.findIndex(
                  option => option.value === field.value,
                )}
                onChange={selectedIndex =>
                  field.onChange(
                    generatedCountryOptions[selectedIndex]?.value || '',
                  )
                }
                alignment="center"
                options={generatedCountryOptions}
                type="text"
                dropDownMaxHeight="250px"
                isDropDownAbove
                focusColor={errors.country ? 'danger' : undefined}
                message={
                  errors.country
                    ? { type: 'danger', content: errors.country.message! }
                    : undefined
                }
              />
            )}
          />
        </Div>

        {/** State */}

        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          outset={{ top: 'x3', bottom: 'x1' }}
        >
          <Button
            width="300px"
            backgroundColor="#303030"
            isSubmit
            title="Save Address"
          />
        </Flex>
      </Flex>

      {isLoading && <LoadingSpinner size="small" />}
    </form>
  )
}
