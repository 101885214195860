import useSelector from './useSelector'
import { orphanSponsorsGenealogyIds } from 'src/app/constants'
import { AccountFragment } from 'src/graphql/generated/graphql'

type AccountExtras = {
  isOrphan: boolean
  hasAddress: boolean
  hasPaymentMethod: boolean
  isProfileComplete: boolean
}

const useAccount = (): AccountFragment & AccountExtras => {
  const storedData = useSelector(state => state.user)

  const account = storedData.account
  if (!storedData || !account || !storedData.controlData.token) {
    throw new Error('unauthorized')
  }

  const isOrphan = account.sponsor
    ? orphanSponsorsGenealogyIds.find(
        value => account.sponsor && account.sponsor.genealogyId === value,
      ) !== undefined
    : false
  const hasAddress = !!account.shippingAddress
  const hasPaymentMethod = !!account.stripeSource

  const profileFields = [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'profileImageUrl',
  ]
  const isProfileComplete = profileFields.every(
    field => !!account[field as keyof AccountFragment],
  )

  return {
    ...account,
    isOrphan,
    hasAddress,
    hasPaymentMethod,
    isProfileComplete,
  }
}

export default useAccount
