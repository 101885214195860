import React from 'react'
import Switch from '@material-ui/core/Switch'
// @src imports
import { DefaultError, Transition } from 'src/chrome'
import { noop } from 'src/helpers/appHelpers'
import { isMixedPurchase, isOrderFree } from 'src/helpers/payments'
import { RandomRunner, recipientCount } from 'src/helpers'

import { AffiliateFormInfo } from 'src/marketing/plans/components/AffiliateForm/AffiliateForm'
import {
  useActions,
  useCallback,
  useEffect,
  useMutations,
  usePersistedUserData,
  useQueries,
  useQueryParams,
  useRef,
  useSelector,
  useState,
  useVertical,
} from 'src/hooks'
import {
  AddressFragment,
  getFairUseMarketingContent,
  PlanFragment,
  StripeOrderFragment,
} from 'src/legacy_graphql'

import {
  AffiliateContext,
  BuyButton,
  ExpenseReloadForm,
  ItemsContext,
  OrderContext,
  PaymentMethod,
  PaymentPhoneNumberVerification,
  PaymentSuccess,
  PlanContext,
  ShippingAddress,
} from 'src/payments/components'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'

import { FlexColumn } from 'src/styled'
import { Item } from 'src/campaign_store/types'

// relative imports
import styles from './styles'
import SendFreeCardSuccess from 'src/onboarding/SendFreeCardSuccess'
import {
  Button,
  ConfirmDialog,
  Div,
  Icon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Memoized } from 'src/hooks/dependencies'
import { CircularProgress } from '@material-ui/core'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { parseError } from 'src/utils/parseError'
import {
  AccountInput,
  Amount,
  Currency,
  ExpenseReloadFragment,
} from 'src/graphql/generated/graphql'
import {
  queryKeyStore,
  useCreateAffiliate,
  useCreditsBalance,
  usePurchaseProduct,
  useUpdateAccount,
} from 'src/react_query'
import { useQueryClient } from '@tanstack/react-query'

export enum PurchaseTypes {
  PLAN = 'PLAN',
  ITEMS = 'ITEMS',
  ORDER = 'ORDER',
  DISTRIBUTOR = 'DISTRIBUTOR',
}

export type ItemsPaymentInfo = {
  purchaseType: PurchaseTypes.ITEMS
  data?: undefined
  items: Item[]
  coupon?: string | null
}

export type AffiliatePaymentInfo = {
  purchaseType: PurchaseTypes.DISTRIBUTOR
  data: AffiliateFormInfo
  items?: undefined
}

export type OrderPaymentInfo = {
  purchaseType: PurchaseTypes.ORDER
  data?: undefined
  items: OrderWithPartialLines[]
  currencyNeeded?: boolean
}

export type PlanPaymentInfo = {
  purchaseType: PurchaseTypes.PLAN
  data?: undefined
  items: PlanFragment[]
}

export type PaymentInfo =
  | ItemsPaymentInfo
  | AffiliatePaymentInfo
  | OrderPaymentInfo
  | PlanPaymentInfo

const purchaseMessages = [
  'Contacting Provider',
  'Initializing Purchase',
  'Processing',
  'Completing Purchase',
  'Verifying Purchase',
  'Almost Finished',
  'Just Checking a Few More Things',
]

interface Props {
  paymentInfo?: PaymentInfo
  onOrderReload?: Memoized<() => void>
  onPurchaseStart?: () => void
  onPurchaseSuccess?: () => void
  onPurchaseError?: (error: Error | string) => void
  onPurchasingChange?: (purchasing: boolean) => void
  onClose?: () => void
  onPaymentSuccess?: () => void
}

const Payment: React.FC<Props> = props => {
  const {
    paymentInfo,
    onOrderReload,
    onPurchaseStart,
    onPurchaseSuccess,
    onPurchaseError,
    onPurchasingChange,
    onClose,
    onPaymentSuccess,
  } = props

  const [stripeOrder, setStripeOrder] = useState<StripeOrderFragment | null>(
    null,
  )
  const { isMobile } = useSelector(state => state.window)
  const { account } = usePersistedUserData()
  const card = useSelector(state => state.orders.activeLine?.card)
  const { order } = useSelector(state => state.orders)
  const queryClient = useQueryClient()

  const actions = useActions()
  const mutations = useMutations()
  const updateAccountMutation = useUpdateAccount()
  const { mutateAsync: purchaseProductMutation } = usePurchaseProduct()
  const createAffiliateMutation = useCreateAffiliate()
  const vertical = useVertical()
  const queryParams = useQueryParams()
  const creditsResult = useCreditsBalance(account?.isRep)
  const [fairUseMarketingContent] = useQueries(getFairUseMarketingContent())

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogError, setDialogError] = useState<string | undefined>()
  const [hasVerifiedPhoneNumber, setHasVerifiedPhoneNumber] = useState(
    !!account?.verifiedPhoneNumber,
  )

  const [transitionMessage, setTransitionMessage] = useState<{
    message: string
    subMessage?: string
  }>({ message: '' })

  const [isExpenseReloadVisible, setIsExpenseReloadVisible] = useState(false)

  const [expenseReload, setExpenseReload] = useState<ExpenseReloadFragment>({
    purchaseAmount: 10,
    threshold: 100,
    isEnabled: false,
    isRequired: false,
    __typename: 'ExpenseReload',
  })

  const [isUsingCredits, setIsUsingCredits] = useState(false)
  const [isPurchasing, setIsPurchasing] = useState(false)
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false)

  const [shouldShowSignUpFlow, setShouldShowSignUpFlow] = useState(
    account && account.username === '',
  )

  const mutablePurchaseMessageId = useRef(0)
  const randoPlanPurchaseMessageRunner = useRef(
    new RandomRunner(2000, 5000, () => changePurchaseMessage()),
  )

  const credits = useCallback(
    (): Amount =>
      creditsResult.data
        ? creditsResult.data
        : {
            amount: 0,
            currency: Currency.Unavailable,
            description: '0.00',
            __typename: 'Amount',
          },
    [creditsResult],
  )

  const updateAccount = async (
    account: AccountInput,
    success = noop,
    error: (error: Error | string) => void = noop,
  ) => {
    try {
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({ account })
      actions.updatedAccount(updatedAccount)
      success()
    } catch (err) {
      error(parseError(err))
      try {
        const errorMessage =
          err instanceof Error ? err.message.split(': ')[1] : 'An error occured'
        setDialogError(errorMessage)
      } catch {
        setDialogError(
          'Account failed to update. Contact Support for assistance.',
        )
      }
      setIsDialogOpen(false)
    }
  }

  const purchase = () => {
    if (!paymentInfo) return

    onPurchaseStart?.()
    setIsPurchasing(true)
    onPurchasingChange?.(true)

    switch (paymentInfo?.purchaseType) {
      case PurchaseTypes.PLAN:
        startChangePurchaseMessage()
        return purchasePlan(paymentInfo?.items)
      case PurchaseTypes.ITEMS:
        if (!stripeOrder) return
        return processItemPurchase(stripeOrder.id)
      case PurchaseTypes.ORDER:
        if (order) {
          return sendOrder(order.id)
        } else {
          return
        }
      case PurchaseTypes.DISTRIBUTOR:
        return purchaseAffiliate(paymentInfo?.data)
      default:
        return
    }
  }

  const purchasePlan = (plans: Array<PlanFragment>) => {
    const plansFormatted = plans.reduce((o: AccountInput, plan) => {
      return plan.isAddon
        ? { ...o, planAddon: plan.id }
        : { ...o, plan: plan.id }
    }, {} as AccountInput)

    const accountData = isExpenseReloadVisible
      ? ({
          ...plansFormatted,
          expenseReload: {
            ...expenseReload,
            threshold: expenseReload.threshold / 100,
            isEnabled: true,
          },
        } as AccountInput)
      : plansFormatted

    updateAccount(accountData, purchaseSuccess, purchaseError)
  }

  const processItemPurchase = async (orderId: string) => {
    try {
      const {
        purchaseProduct: { purchased: didPurchase, error },
      } = await purchaseProductMutation({ orderId: orderId })
      if (didPurchase) {
        purchaseSuccess()
      } else {
        purchaseError(error || 'Unknown Error')
      }
      setIsDialogOpen(false)
    } catch (err) {
      purchaseError(parseError(err))
    }
  }

  const updateShouldDisplayUpsale = async () => {
    try {
      const {
        shouldDisplayUpsaleTrigger: { shouldDisplayUpsale },
      } = await mutations.shouldDisplayUpsaleTrigger({
        doNotShowDefaultTransition: true,
      })
      if (account) {
        actions.updatedAccount({ ...account, shouldDisplayUpsale })
      }
    } catch (err) {
      console.log('Could not fetch display upsale data')
    }
  }

  const sendOrder = async (id: string) => {
    if (!order || !account) return

    try {
      const {
        sendOrder: {
          order: { id: orderId },
        },
      } = await mutations.sendOrder({
        order: id,
        buyPointsAndExpense: order.isBulk
          ? false
          : isMixedPurchase(order, account, credits(), isUsingCredits),
        shouldUseCredits: order.isBulk ? false : isUsingCredits,
      })
      if (orderId) {
        queryParams.promotion && window._cio.track('postcard_promo_send')
        setTimeout(() => {
          queryClient.invalidateQueries(queryKeyStore.upsell._def)
        }, 5000)
        return purchaseSuccess()
      } else {
        purchaseError(
          'Something went wrong while sending your order, please try again later',
        )
      }
    } catch (err) {
      return purchaseError(parseError(err))
    } finally {
      updateShouldDisplayUpsale()
    }
  }

  const purchaseAffiliate = async (affiliate: AffiliateFormInfo) => {
    const { govId1, govId2, phoneNumber, user } = affiliate
    try {
      const {
        createDistributor: { account, error },
      } = await createAffiliateMutation.mutateAsync({
        account: {
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber,
          shippingAddress: user,
        },
        govId1,
        govId2,
        verticalId: vertical.graphqlVerticalID,
        queryParams: {
          redirect_uri: queryParams.redirect_uri,
        },
      })

      if (error) {
        purchaseError(error)
      } else if (account) {
        actions.updatedAccount(account)
        purchaseSuccess()
      }
    } catch (e) {
      purchaseError(parseError(e))
    }
  }

  const purchaseSuccess = () => {
    if (!paymentInfo) return

    setIsPurchasing(false)
    setIsPaymentSuccessful(true)

    onPurchaseSuccess?.()
    onPurchasingChange?.(false)
  }

  const purchaseError = (error: Error | string) => {
    if (!paymentInfo) return

    setIsPurchasing(false)
    setIsPaymentSuccessful(false)

    onPurchaseError?.(error)
    console.error(error)
    onPurchasingChange?.(false)
  }

  const addCardError = (error?: Error | string) => {
    if (!paymentInfo) return

    setIsPurchasing(false)
    setIsPaymentSuccessful(false)

    onPurchaseError?.(error || 'Unknown Error')
    console.error(error)
    onPurchasingChange?.(false)
  }

  const getContext = () => {
    if (!paymentInfo) return null

    switch (paymentInfo?.purchaseType) {
      case PurchaseTypes.PLAN:
        return (
          <PlanContext plans={paymentInfo?.items}>
            <>
              <Spacer space="x5" />
              <Text type="caption" inset={{ left: 'x1' }}>
                {isExpenseReloadVisible
                  ? 'Configure your Expense Bucket'
                  : 'Add an Expense Bucket'}
              </Text>
              {!isExpenseReloadRequired() && (
                <Switch
                  checked={isExpenseReloadVisible}
                  onChange={() => setIsExpenseReloadVisible(x => !x)}
                />
              )}
              {isExpenseReloadVisible && (
                <>
                  <ExpenseReloadForm
                    expenseReload={expenseReload}
                    onChange={handleChangeExpense}
                  />
                  <Spacer space="x3" />
                  <Text
                    type="footnote"
                    inset="x2"
                  >{`*We will charge $${expenseReload.purchaseAmount} of expense to your card today`}</Text>
                </>
              )}
            </>
          </PlanContext>
        )
      case PurchaseTypes.ITEMS:
        return (
          <ItemsContext
            items={paymentInfo?.items}
            stripeOrder={stripeOrder ?? undefined}
          />
        )
      case PurchaseTypes.ORDER:
        if (order) {
          return (
            <OrderContext
              order={order}
              credits={credits()}
              isUsingCredits={isUsingCredits}
            />
          )
        } else {
          return
        }
      case PurchaseTypes.DISTRIBUTOR:
        return <AffiliateContext affiliate={paymentInfo?.data} />
      default:
        return
    }
  }

  const changePurchaseMessage = () => {
    if (purchaseMessages.length === mutablePurchaseMessageId.current) {
      randoPlanPurchaseMessageRunner &&
        randoPlanPurchaseMessageRunner.current.stop()
      return
    }
    setTransitionMessage({
      message: purchaseMessages[mutablePurchaseMessageId.current] + '...',
    })
    mutablePurchaseMessageId.current++
  }

  const startChangePurchaseMessage = () => {
    setTransitionMessage({ message: purchaseMessages[0] + '...' })
    mutablePurchaseMessageId.current = 1
    randoPlanPurchaseMessageRunner &&
      randoPlanPurchaseMessageRunner.current.start()
  }

  const getTransitionMessage = (purchaseType: PurchaseTypes) => {
    switch (purchaseType) {
      case PurchaseTypes.PLAN:
        return { message: 'Purchasing subscription...' }
      case PurchaseTypes.ITEMS:
        return { message: 'Purchasing item...' }
      case PurchaseTypes.ORDER:
        return {
          message: 'Processing order...',
          subMessage:
            order && recipientCount(order) > 350
              ? '(this could take several minutes due to the large number of contacts)'
              : undefined,
        }
      case PurchaseTypes.DISTRIBUTOR:
      default:
        return { message: 'Processing payment...' }
    }
  }

  const updateTransitionMessage = () => {
    if (!paymentInfo) return

    const message = getTransitionMessage(paymentInfo.purchaseType)
    if (
      message.message !== transitionMessage.message &&
      paymentInfo?.purchaseType !== PurchaseTypes.PLAN
    ) {
      setTransitionMessage(message)
    }
  }

  const createStripeOrder = async (
    itemCode: string,
    coupon?: string | null,
  ) => {
    try {
      const {
        createStripeOrder: { stripeOrder },
      } = await mutations.createStripeOrder({
        coupon: coupon,
        itemsToPurchase: [{ itemCode: itemCode, quantity: 1 }],
      })
      setStripeOrder(stripeOrder)
    } catch (error) {
      purchaseError(parseError(error))
    }
  }

  const handleChangeExpense = (name: string, value: number) => {
    setExpenseReload(x => ({
      ...x,
      [name]: value,
    }))
  }

  const isExpenseReloadRequired = useCallback((): boolean => {
    if (
      !paymentInfo ||
      paymentInfo.purchaseType !== PurchaseTypes.PLAN ||
      !paymentInfo.items
    )
      return false
    return (
      paymentInfo.items.find(item => item.isExpenseReloadRequired) !== undefined
    )
  }, [paymentInfo])

  const handleClose = (): void => {
    setIsPurchasing(false)
    setIsPaymentSuccessful(false)

    onClose?.()
  }

  const onCloseSendFreeCardSuccess = useCallback(() => {
    setIsDialogOpen(false)
    if (account && account.username !== '') {
      setShouldShowSignUpFlow(false)
      onOrderReload?.()
    }
  }, [account, onOrderReload])

  useEffect(() => {
    if (
      !stripeOrder &&
      paymentInfo?.items &&
      paymentInfo?.purchaseType === PurchaseTypes.ITEMS
    ) {
      createStripeOrder(paymentInfo.items[0].itemCode)
    }
  })

  useEffect(() => {
    setIsExpenseReloadVisible(!isExpenseReloadRequired())
  }, [paymentInfo, isExpenseReloadRequired])

  useEffect(() => {
    updateTransitionMessage()
    const runner = randoPlanPurchaseMessageRunner.current

    return () => {
      if (stripeOrder) {
        setStripeOrder(null)
      }
      runner.stop()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  if (!paymentInfo) return null

  const isPaymentMethodVisible =
    paymentInfo?.purchaseType !== PurchaseTypes.ORDER ||
    !!(order && !isOrderFree(order))

  const isMixed =
    order && account
      ? isMixedPurchase(order, account, credits(), isUsingCredits)
      : false

  const accountData =
    account && paymentInfo?.purchaseType === PurchaseTypes.DISTRIBUTOR
      ? {
          ...account,
          shippingAddress: {
            ...paymentInfo?.data.user,
            __typename: 'Address',
          } as AddressFragment,
        }
      : account && paymentInfo?.purchaseType === PurchaseTypes.ORDER && order
        ? { ...account, shippingAddress: order.returnAddress }
        : account

  const isPlanPurchase = paymentInfo?.purchaseType === PurchaseTypes.PLAN
  const isSparse = !account?.username

  return (paymentInfo?.data || paymentInfo?.items.length) &&
    account &&
    accountData ? (
    <div style={{ position: isMobile ? undefined : 'relative' }}>
      {!isPaymentSuccessful && (
        <div
          css={styles.paymentWrapper}
          style={{ ...(isPlanPurchase && { padding: '0 25px' }) }}
        >
          <div css={styles.accountColumn}>
            {!isMobile && !order?.isBulk && (
              <ShippingAddress
                account={accountData}
                isEditable={paymentInfo.purchaseType !== 'ORDER'}
                title={'Return Address'}
              />
            )}
            {isPaymentMethodVisible && (
              <div css={styles.sections}>
                <div css={styles.sectionTitle}>
                  <Text type="body">PAYMENT METHOD</Text>
                </div>
                <PaymentMethod
                  returnAddress={
                    order?.returnAddress ? order.returnAddress : undefined
                  }
                  account={accountData}
                  paymentInfo={paymentInfo}
                  onError={addCardError}
                  isMixedOrder={isMixed}
                  credits={credits()}
                  canUseCredits={
                    account.isRep &&
                    credits().currency === Currency.Usd &&
                    credits().amount > 0
                  }
                  isUsingCredits={isUsingCredits}
                  setIsUsingCredits={setIsUsingCredits}
                  isBulk={order?.isBulk}
                />
              </div>
            )}
            <Div outset={{ top: 'auto' }} display="flex" alignItems="center">
              <Icon
                name="information"
                size="small"
                primaryColor="primaryBodyText"
              />
              <Spacer space="x1" orientation="horizontal" />
              <Text type="caption">
                By clicking "Send" you are agreeing to our{' '}
                <a
                  href={fairUseMarketingContent.fairUsePolicyLink.url}
                  style={{
                    fontFamily: 'inherit',
                    color: 'inherit',
                    fontWeight: 500,
                    textDecoration: 'underline',
                  }}
                  data-mktcontent="link:fairUsePolicyLink"
                >
                  Fair Use Policy
                </a>
                .
              </Text>
            </Div>
          </div>
          <div css={styles.orderColumn}>
            <Text type="body">{getContext()}</Text>
          </div>
          {isMobile && !order?.isBulk && (
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <ShippingAddress
                account={accountData}
                isEditable={paymentInfo.purchaseType !== 'ORDER'}
                title={'Return Address 1'}
              />
            </div>
          )}
          <div
            css={styles.paymentAction}
            style={
              isMobile && card
                ? {
                    position: 'sticky',
                    bottom: '-1vh',
                  }
                : {}
            }
          >
            <Button
              type="danger"
              onClick={handleClose}
              title="Cancel"
              outlined={true}
            />
            {!shouldShowSignUpFlow || (order && isOrderFree(order)) ? (
              <>
                {!hasVerifiedPhoneNumber && isSparse && (
                  <PaymentPhoneNumberVerification
                    onVerifySuccess={() => {
                      setHasVerifiedPhoneNumber(true)
                      onOrderReload?.()
                    }}
                  />
                )}
                <BuyButton
                  account={accountData}
                  onPurchase={purchase}
                  order={order}
                  purchaseType={paymentInfo?.purchaseType}
                  credits={credits()}
                  isUsingCredits={isUsingCredits ? true : undefined}
                />
              </>
            ) : (
              <div>
                <Button
                  id={'set_up_account'}
                  onClick={() => setIsDialogOpen(true)}
                  title="Set Up My Account"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {isPaymentSuccessful &&
        account &&
        account.username !== '' &&
        !shouldShowSignUpFlow && (
          <FlexColumn
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PaymentSuccess isBulk={order?.isBulk} />
            {isPlanPurchase && (
              <Button
                title={"Let's send a card"}
                onClick={() => {
                  onPaymentSuccess?.()
                  actions.openCatalog()
                }}
              />
            )}
          </FlexColumn>
        )}
      {(isDialogOpen || (isPaymentSuccessful && shouldShowSignUpFlow)) && (
        <SendFreeCardSuccess
          orderNumber={isPaymentSuccessful ? order?.id : undefined}
          onClose={onCloseSendFreeCardSuccess}
          onCloseAndPay={() => purchase()}
        />
      )}
      {dialogError && (
        <div>
          <ConfirmDialog
            isOpen={dialogError ? true : false}
            title={'Purchase has Failed'}
            description={dialogError}
            accept={{
              title: 'Got it',
              onClick: () => setDialogError(undefined),
            }}
            primaryAction={'accept'}
            onClose={() => setDialogError(undefined)}
          />
        </div>
      )}
      {isPurchasing && (
        <Transition
          message={transitionMessage.message}
          subMessage={transitionMessage.subMessage}
          style={{
            position: 'absolute',
            height: '100%',
          }}
        />
      )}
    </div>
  ) : null
}

export default suspenseBoundary({
  component: Payment,
  unresolved: <CircularProgress />,
  failure: DefaultError,
})
