import React from 'react'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import DynamicSubscriptionSelection from './DynamicSubscriptionSelection'
import { PlanIdType } from 'src/pricing_page/components/pricingTile/PlanTiles'
import styles from './dynamicSubSelectorDialog.module.scss'
import { Icon, Text } from '@sendoutcards/quantum-design-ui'
import { useSelector } from 'src/hooks'

type DynamicSubSelectorDialogProps = {
  planIds: PlanIdType[]
  isOpen: boolean
  onClose: () => void
}

const DynamicSubSelectorDialog: React.FC<
  DynamicSubSelectorDialogProps
> = props => {
  const { planIds, isOpen, onClose } = props
  const isMobile = useSelector(state => state.window.width <= 520)

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      shouldHideTitleBar={true}
      mobileOpenHeight="16px"
      wrapperStyle={{ overflow: 'hidden', maxHeight: 'calc(100dvh - 16px)' }}
      padding={!isMobile ? '60px 40px 40px' : undefined}
    >
      <div className={styles.header}>
        <div
          className={styles.back}
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        >
          <Icon name="leftChevron" size="medium" primaryColor="#000" />
        </div>
        <div className={styles.title}>
          <Text type="body" weight="bold" alignment="center">
            All Plans
          </Text>
        </div>
      </div>
      <DynamicSubscriptionSelection
        plansArray={planIds}
        wrapperStyle={{ overflow: 'auto', maxHeight: 'calc(100dvh - 150px)' }}
      />
    </Dialog>
  )
}

export default DynamicSubSelectorDialog
