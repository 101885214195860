import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import {
  SelectableUpsaleOptions,
  SelectableUpsaleOptionsType,
} from './components/SelectableUpsaleOptions'
import {
  UpsaleFeatureList,
  UpsaleListProps,
} from './components/UpsaleFeatureList'
import { useSelector, useState } from 'src/hooks'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { UpsaleHeader } from './components/UpsaleHeader'
import { UpsaleFooter } from './components/UpsaleFooter'

type ActionType = {
  title: string
  onClick: () => void
}

type PlanType = {
  title: string
  description: string
  planOptions: SelectableUpsaleOptionsType
  planFeatureList: UpsaleListProps
}

type FullPageUpsaleProps = {
  plan: PlanType
  isOpen: boolean
  upsaleImage: string
  primaryAction: ActionType
  secondaryAction?: ActionType
  backgroundGradient?: string
  onShowTerms?: () => void
  onViewAll: () => void
  onClose: () => void
}

export const FullPageUpsale = (props: FullPageUpsaleProps) => {
  const {
    plan,
    isOpen,
    upsaleImage,
    primaryAction,
    secondaryAction,
    backgroundGradient = 'radial-gradient(circle at 80% 0%, #9859fe 0%, transparent 45%),linear-gradient(180deg, #9859fe -30.21%, #f075d4 25.82%, white 100%)',
    onShowTerms,
    onViewAll,
    onClose,
  } = props
  const isMobile = useSelector(state => state.window.width <= 760)
  const [isHovering, setIsHovering] = useState(false)

  const fontFamily = 'Montserrat, sans-serif'

  const imgSize = isMobile ? '300px' : '400px'

  const wrapperStyles = {
    width: '100%',
    height: '100%',
    background: backgroundGradient,
    zIndex: 1000,
    position: 'fixed',
    overflowY: 'auto',
    top: '0',
    left: '0',
    justifyContent: 'center',
    alignItems: isMobile ? 'flex-start' : 'center',
  } as const

  const actionsStyles = {
    padding: '8px 48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#000',
    color: '#fff',
    borderRadius: '16px',
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
    fontSize: '16px',
    fontFamily: fontFamily,
    fontWeight: 600,
    flexDirection: 'row' as const,
    columnGap: '5px',
    cursor: 'pointer',
    transform: isHovering ? 'scale(1.03)' : 'initial',
    transition: '.3s ease-in-out',
  }

  return (
    <>
      {isOpen && (
        <Flex style={{ ...wrapperStyles }}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: '40px' }}
          >
            <UpsaleHeader
              onClose={onClose}
              inset={{ bottom: '40px', horizontal: isMobile ? 'x3' : '' }}
            />
            <Flex
              justifyContent="center"
              alignItems="center"
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
            >
              <Flex flexDirection="column" alignItems="center">
                <Div
                  style={{
                    width: imgSize,
                    height: imgSize,
                    backgroundImage: `url(${upsaleImage})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                  }}
                />
                <Flex flexDirection="column" alignItems="center" rowGap="x2">
                  <Text
                    type="body"
                    content={plan.title}
                    style={{
                      color: '#212121',
                      fontFamily: fontFamily,
                      fontSize: '28px',
                      fontWeight: 700,
                    }}
                  />
                  <Div maxWidth="230px">
                    <Text
                      type="body"
                      alignment="center"
                      lineHeight={1.5}
                      content={plan.description}
                      style={{
                        fontFamily: fontFamily,
                        color: '#404040',
                        fontWeight: 600,
                        fontSize: '14px',
                      }}
                    />
                  </Div>
                  <div>
                    <Div
                      onClick={primaryAction.onClick}
                      onHoverStart={() => setIsHovering(true)}
                      onHoverEnd={() => setIsHovering(false)}
                      style={{ ...actionsStyles }}
                    >
                      <Icon name="premium_crown" color="#FECD39" size={14} />
                      {primaryAction.title}
                    </Div>
                    {secondaryAction && (
                      <>
                        <Text
                          type="caption"
                          content="Or"
                          alignment="center"
                          weight="bold"
                        />
                        <Div
                          onClick={secondaryAction.onClick}
                          onHoverStart={() => setIsHovering(true)}
                          onHoverEnd={() => setIsHovering(false)}
                          style={{
                            ...actionsStyles,
                            backgroundColor: '#FFF',
                            color: 'rgb(75, 85, 99)',
                          }}
                        >
                          {secondaryAction.title}
                        </Div>
                      </>
                    )}
                  </div>
                </Flex>
              </Flex>
              <Flex
                flexDirection="column"
                rowGap="x1"
                justifyContent="center"
                alignItems="center"
                inset={isMobile ? { top: 'x3' } : {}}
              >
                <SelectableUpsaleOptions {...plan.planOptions} />
                <UpsaleFeatureList {...plan.planFeatureList} />
              </Flex>
            </Flex>
            <UpsaleFooter
              onShowTerms={onShowTerms}
              onViewAll={onViewAll}
              inset={{ top: '60px', bottom: '100px' }}
            />
          </Flex>
        </Flex>
      )}
    </>
  )
}
