import React, { FC } from 'react'
import { SVGProps } from './types'

export const TrophyBadge: FC<SVGProps> = ({ size = 86, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 86 86"
      width={size}
      height={size}
      onClick={onClick}
    >
      <g filter="url(#filter0_d_5781_23502)">
        <rect
          x={13}
          y={13}
          width={60}
          height={60}
          rx={30}
          fill="white"
          fillOpacity={0.2}
          shapeRendering="crispEdges"
        />
        <path
          d="M51.8755 52.6016H35.2063V59.0009H51.8755V52.6016Z"
          stroke="#FECD39"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.7384 28.0128V36.6528C49.7384 39.1791 48.2962 41.3605 46.2126 42.3806C45.4045 42.7776 44.4985 43.0002 43.5382 43.0002C42.578 43.0002 41.6757 42.7776 40.8676 42.3806C40.2298 42.069 39.6536 41.6461 39.1572 41.1416C38.0338 39.9916 37.3417 38.4075 37.3417 36.6528V28.0128C37.3417 27.4563 37.7838 27 38.331 27H48.7492C49.2964 27 49.7384 27.4563 49.7384 28.0128Z"
          stroke="#FECD39"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.538 48.832H38.5365V52.6011H48.538V48.832Z"
          stroke="#FECD39"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.538 48.8321H38.5365C38.7213 48.7505 38.8989 48.6429 39.0692 48.5131C39.7939 47.9566 40.3592 47.0032 40.6346 45.9051C40.9173 44.7811 40.9535 43.5717 40.8666 42.3809C41.6746 42.7778 42.5806 43.0004 43.5372 43.0004C44.4939 43.0004 45.4035 42.7778 46.2116 42.3809C46.121 43.568 46.1608 44.7811 46.4435 45.9051C46.7189 47.0032 47.2806 47.9566 48.0053 48.5131C48.1756 48.6429 48.3532 48.7505 48.538 48.8321Z"
          stroke="#FECD39"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.7056 30.6286C51.4936 29.8792 52.0009 29.0371 52.7193 28.7756C53.4377 28.514 54.2721 28.7691 54.8347 29.2949C55.3972 29.8208 55.7125 30.5785 55.8466 31.3464C56.141 33.0381 55.573 34.8633 54.3772 36.069C53.1804 37.2746 51.3894 37.8274 49.7415 37.4991M35.2176 30.6379C35.4296 29.8885 34.9223 29.0464 34.2039 28.7849C33.4864 28.5233 32.6511 28.7784 32.0885 29.3042C31.5259 29.8301 31.2107 30.5878 31.0766 31.3557C30.7822 33.0474 31.3502 34.8726 32.546 36.0782C33.7428 37.2839 35.5338 37.8367 37.1817 37.5083"
          stroke="#FECD39"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g filter="url(#filter1_i_5781_23502)">
          <circle cx={43} cy={43} r={28.5} stroke="#E7E7E7" strokeWidth={3} />
        </g>
        <g filter="url(#filter2_i_5781_23502)">
          <mask id="path-8-inside-1_5781_23502" fill="white">
            <path d="M73 43C73 59.5685 59.5685 73 43 73C26.4315 73 13 59.5685 13 43C13 26.4315 26.4315 13 43 13C59.5685 13 73 26.4315 73 43ZM16.4321 43C16.4321 57.673 28.327 69.5679 43 69.5679C57.673 69.5679 69.5679 57.673 69.5679 43C69.5679 28.327 57.673 16.4321 43 16.4321C28.327 16.4321 16.4321 28.327 16.4321 43Z" />
          </mask>
          <path
            d="M73 43C73 59.5685 59.5685 73 43 73C26.4315 73 13 59.5685 13 43C13 26.4315 26.4315 13 43 13C59.5685 13 73 26.4315 73 43ZM16.4321 43C16.4321 57.673 28.327 69.5679 43 69.5679C57.673 69.5679 69.5679 57.673 69.5679 43C69.5679 28.327 57.673 16.4321 43 16.4321C28.327 16.4321 16.4321 28.327 16.4321 43Z"
            stroke="#FECD39"
            strokeWidth={6}
            mask="url(#path-8-inside-1_5781_23502)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_5781_23502"
          x={0}
          y={0}
          width={86}
          height={86}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={6.5} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5781_23502"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5781_23502"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_5781_23502"
          x={13}
          y={13}
          width={60}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_5781_23502"
          />
        </filter>
        <filter
          id="filter2_i_5781_23502"
          x={13}
          y={13}
          width={60}
          height={60}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_5781_23502"
          />
        </filter>
      </defs>
    </svg>
  )
}
