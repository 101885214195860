import React from 'react'
import {
  MiniUpsales,
  UpsaleSizeType,
  UpsaleType,
} from '../MiniUpsales.tsx/MiniUpsales'
import { useCurrentUpsale } from 'src/hooks/useCurrentUpsale'
import { useActions, useSelector } from 'src/hooks'
import { useFormatSOCMediaUrl } from 'src/hooks/useFormatSOCMediaUrl'
import { PremiumCardUpsale } from '../PremiumCardUpsale/PremiumCardUpsale'
import usePlanInfo from 'src/hooks/usePlanInfo'

type CurrentMiniUpsaleProps = {
  size?: UpsaleSizeType
  type?: UpsaleType
  shouldReverseOrder?: boolean
  shouldReverseAction?: boolean
  shouldStackAction?: boolean
  imageWidth?: string
  imageHeight?: string
}

export const CurrentMiniUpsale = (props: CurrentMiniUpsaleProps) => {
  const {
    size = 'Large',
    type = 'Ocean',
    shouldReverseOrder,
    shouldReverseAction,
    shouldStackAction,
    imageWidth,
    imageHeight,
  } = props
  const { plan } = useCurrentUpsale()
  const { upsaleInfo } = usePlanInfo(plan?.id)

  const actions = useActions()
  const { shouldShowUpsell } = useSelector(state => state.upsell)

  const image = useFormatSOCMediaUrl(upsaleInfo.miniUpsale.image)

  if (!plan) return <></>

  if (shouldShowUpsell) {
    return <PremiumCardUpsale />
  }

  return (
    <MiniUpsales
      size={size}
      type={type}
      shouldReverseAction={shouldReverseAction}
      shouldReverseOrder={shouldReverseOrder}
      shouldStackAction={shouldStackAction}
      image={{ imgUrl: image, width: imageWidth, height: imageHeight }}
      title={upsaleInfo.miniUpsale.description ?? ''}
      primaryAction={{
        title: plan.btnTitle,
        onClick: () => {
          actions.setUpsellSlug('WILDCARD')
          actions.setPlanToDisplay(plan.id)
          actions.setShouldShowUpsell(true)
        },
      }}
    />
  )
}
