import React from 'react'
import { Flex } from '@sendoutcards/quantum-design-ui'
import { useSelector, useState } from 'src/hooks'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { PaymentSettingsCard } from 'src/dashboard/containers/Views/PlansAndPayment/components/PaymentSettings/PaymentSettingsCard'

type PaymentMethodViewType = {
  onClose: () => void
}

export const PaymentMethodView = ({ onClose }: PaymentMethodViewType) => {
  const [shouldShowPaymentMethodView, setShouldShowPaymentMethodView] =
    useState(true)
  const isMobile = useSelector(state => state.window.width <= 580)

  const handleClose = () => {
    setShouldShowPaymentMethodView(false)
    onClose()
  }

  return (
    <Dialog
      isOpen={shouldShowPaymentMethodView}
      onClose={handleClose}
      mobileOpenHeight={'calc(100dvh - 470px)'}
      outerWrapperStyle={{
        width: isMobile ? 'unset' : 'fit-content',
        minWidth: isMobile ? 'unset' : '578px',
      }}
      sheetBgColorOverride="#fff"
      mobileBreakPointOverride={580}
    >
      <Flex id="account_payment_method_container" justifyContent="center">
        <PaymentSettingsCard formBackgroundOverride="#fff" />
      </Flex>
    </Dialog>
  )
}
