import React from 'react'
// @src imports
import {
  useActions,
  useEffect,
  useFlag,
  usePersistedUserData,
  useSelector,
  useState,
  useRoute,
} from 'src/hooks'

import { Flex, Text } from '@sendoutcards/quantum-design-ui'

import PromptingsUpcomingEvents from '../../../components/PromptingsUpcomingEvents'
import {
  CollapsibleBanner,
  CollapsibleBannerProps,
} from 'src/saleor/components/collapsable_banner/CollapsableBanner'
import LeaderBoard from '../../../components/Leaderboard/LeaderBoard'
import { AccountSubscriptionChanger } from 'src/SubscriptionChanger/AccountSubscriptionChanger'
import { AffiliateCreationForm } from 'src/saleor/components/affiliate_creation_form/AffiliateCreationForm'
import { SendingMetricsWidget } from 'src/dashboard/components/Widgets/SendingMetrics/SendingMetricsWidget'
import { CardFeatureWidget } from 'src/dashboard/components/Widgets/CardFeature/CardFeatureWidget'
import { SubscriptionWidget } from 'src/dashboard/components/Widgets/Subscription/SubscriptionWidget'
import { CardHistoryWidget } from 'src/dashboard/components/Widgets/History/CardHistoryWidget'
import { AccountSettingsWidget } from 'src/dashboard/components/Widgets/Settings/AccountSettingsWidget'
import { DraftWidget } from 'src/dashboard/components/Widgets/Drafts/DraftWidget'
import { RemindersListWidget } from 'src/dashboard/components/Widgets/Reminders/RemindersListWidget'
import { Label } from 'src/graphql/generated/graphql'
import {
  useAccountQuery,
  useDefaultSubscriptionIds,
  useFlags,
  useLeaderBoards,
  useMarketingContent,
} from 'src/react_query'
import { AFFILIATE_BRANDING } from 'src/app/constants'
import { GratitudeMovementWidget } from 'src/dashboard/components/Widgets/GratitudeMovement/GratitudeMovementWidget'
import { Portal } from 'src/portal/portal'
import ExclusiveOfferScreen from 'src/onboarding/components/ExclusiveOfferScreen'
import { ExclusiveOfferConfirmationScreen } from 'src/onboarding/components/ExclusiveOfferConfirmationScreen'
import { DashboardSplashScreen } from 'src/dashboard/containers/DashboardSplashScreen/DashboardSplashScreen'

type DashboardHomeViewProps = {
  toggleIsAccountDrawerOpen: () => void
}

const DashboardHomeView: React.FC<DashboardHomeViewProps> = ({
  toggleIsAccountDrawerOpen,
}) => {
  const windowWidth = useSelector(state => state.window.width)

  const actions = useActions()
  const {
    controlData: { shouldDisplayJoinConfirmation },
  } = usePersistedUserData()
  const isLeaderboardMobile = useSelector(state => state.window.width <= 1500)
  const shouldColumnWrap = windowWidth <= 1260
  const shouldWelcomeWrap = windowWidth >= 854 && windowWidth <= 1358
  const isTablet = useSelector(state => state.window.width <= 854)
  const isMobile = useSelector(state => state.window.width <= 516)

  const [isSelectingSubscription, setIsSelectingSubscription] = useState(false)
  const [shouldShowAffiliateForm, setShouldShowAffiliateForm] = useState(false)
  const [shouldShowAffiliateBanner, setShouldShowAffiliateBanner] = useState<
    boolean | undefined
  >()
  const [hasClosedExclusiveOffer, setHasClosedExclusiveOffer] = useState(false)

  const { data: account } = useAccountQuery()

  const { data: flags } = useFlags()

  const { actDisplay: hasAct } = useFlag(flags ?? [])
  const route = useRoute()

  const [shouldShowSplashScreen, setShouldShowSplashScreen] = useState(
    'shouldDisplayDashboardSplash' in route.args,
  )
  useEffect(() => {
    if (account && shouldShowAffiliateBanner === undefined) {
      setShouldShowAffiliateBanner(
        account.labels.includes(Label.NeedsAffiliateAccInfo),
      )
    }
  }, [account, shouldShowAffiliateBanner])

  // const isSparseOrSample =
  //   account.type === UserType.SP123 || account.type === UserType.S

  const { data: marketingContent } = useMarketingContent()

  const getBannerData = (): CollapsibleBannerProps | undefined => {
    if (!account || !marketingContent) {
      return undefined
    }
    const profileWarningTitle =
      marketingContent.affiliateProfileWarningTitle.content
    const profileWarningBody =
      marketingContent.affiliateProfileWarningBody.content

    const profileCompleteTitle =
      marketingContent.affiliateProfileCompleteTitle.content

    const bannerBodyStyles = {
      fontSize: '14px',
      color: '#6B7280',
    }

    return account.labels.includes(Label.NeedsAffiliateAccInfo)
      ? {
          type: 'warning',
          title: profileWarningTitle,
          bannerActions: {
            primaryAction: {
              title: `Complete ${AFFILIATE_BRANDING.capitalized} Profile`,
              onClick: () => {
                setShouldShowAffiliateForm(true)
                setShouldShowAffiliateBanner(false)
              },
            },
          },
          children: (
            <Text
              type="body"
              content={profileWarningBody}
              style={{ ...bannerBodyStyles }}
            />
          ),
        }
      : {
          type: 'success',
          title: profileCompleteTitle,
          onClose: () => setShouldShowAffiliateBanner(false),
        }
  }

  const bannerData = getBannerData()

  // Get leaderboard from backend
  const { data: leaderBoards } = useLeaderBoards()
  const [currentLeaderBoard] = leaderBoards ?? [undefined]

  const shouldShowLeaderBoard =
    currentLeaderBoard && currentLeaderBoard.challenges.length > 0
  const LeaderBoardWrapper = () =>
    currentLeaderBoard ? <LeaderBoard {...currentLeaderBoard} /> : <></>

  const isGsa = !!account && account.type === 'GSA'
  const isSparse = !!account && !account.username
  const hasSentFirstFreeCard = !account?.isQualifiedForFirstFreeCard
  const showExlusiveOffer =
    hasAct &&
    !hasClosedExclusiveOffer &&
    !account?.isQualifiedForFirstFreeCard &&
    hasSentFirstFreeCard &&
    isSparse

  const { data } = useDefaultSubscriptionIds({ suspense: true })
  const defaultSubscriptionIds = data ?? []

  const subscriptionIds = defaultSubscriptionIds.map(id => ({ id }))
  return (
    <Flex
      width="100%"
      // height="100%"
      columnGap="24px"
      rowGap="24px"
      flexDirection="column"
    >
      {shouldShowAffiliateBanner && bannerData && (
        <Flex width="100%">
          <CollapsibleBanner
            outset="0px"
            onClose={() => {
              setShouldShowAffiliateBanner(false)
            }}
            {...bannerData}
          />
        </Flex>
      )}
      {shouldShowAffiliateForm && (
        <AffiliateCreationForm
          onSuccess={() => {
            setShouldShowAffiliateForm(false)
            setShouldShowAffiliateBanner(true)
          }}
          onCancel={() => {
            setShouldShowAffiliateForm(false)
            setShouldShowAffiliateBanner(true)
          }}
        />
      )}
      <Flex
        width="100%"
        height="100%"
        columnGap="24px"
        rowGap="24px"
        flexDirection={shouldColumnWrap ? 'column' : 'row'}
      >
        <Flex
          flexGrow={1}
          width="100%"
          height="100%"
          columnGap="24px"
          rowGap="24px"
          flexDirection="column"
        >
          <Flex
            width="100%"
            columnGap="24px"
            height={isTablet ? 'auto' : undefined}
            rowGap="24px"
            flexDirection={isTablet ? 'column' : 'row'}
          >
            <Flex
              columnGap="24px"
              rowGap="24px"
              width={isTablet ? '100%' : '65%'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <GratitudeMovementWidget
                isMobile={isMobile}
                isTablet={isTablet}
                title={
                  isGsa
                    ? 'Gratitude Movement Presentation'
                    : 'Learn about the Gratitude Movement'
                }
              />
              <CardFeatureWidget />
            </Flex>
            <Flex width={isTablet ? '100%' : undefined} flexGrow={1}>
              <SubscriptionWidget
                maxWidth="100%"
                onClick={() =>
                  setIsSelectingSubscription(!isSelectingSubscription)
                }
              />
            </Flex>
          </Flex>
          {isSelectingSubscription && (
            <AccountSubscriptionChanger
              subscriptionPlanIds={subscriptionIds}
              isOpen={true}
              onClose={() => setIsSelectingSubscription(false)}
            />
          )}
          {shouldColumnWrap && (
            <RemindersListWidget isLandscape={shouldWelcomeWrap} />
          )}
          <Flex
            width="100%"
            columnGap="24px"
            rowGap="24px"
            flexDirection={isTablet ? 'column' : 'row'}
          >
            <Flex width="100%" minWidth="380px">
              <CardHistoryWidget />
            </Flex>
            <SendingMetricsWidget />
          </Flex>
          <Flex
            width="100%"
            columnGap="24px"
            rowGap="24px"
            flexDirection={isTablet ? 'column' : 'row'}
          >
            <Flex width="100%" minWidth="380px">
              <DraftWidget />
            </Flex>
            <AccountSettingsWidget
              onToggleAccountDrawer={toggleIsAccountDrawerOpen}
            />
            {!shouldShowLeaderBoard && isLeaderboardMobile && (
              <LeaderBoardWrapper />
            )}

            {shouldShowLeaderBoard && isLeaderboardMobile && (
              <LeaderBoardWrapper />
            )}
            {/* TODO: Need Designs for sales content
          
          <Spacer space="x4" />
          <Text
            type="largeBody"
            weight="bold"
            color="primaryHeading"
            content="Account Add-ons"
            style={{ letterSpacing: '.3px', marginLeft: 4 }}
          />
          <Spacer space="x2_5" /> */}
            {/* {!isSparseOrSample && isAffiliateEligible && <AffiliateUpsale />}
          {!hasStreamVASubscription && (
            <Div outset={{ bottom: 'x1_5' }} data-mktcontent="streamVaPromo">
              <PromotionWidget
                title={marketingContent.streamVaPromo.title}
                description={marketingContent.streamVaPromo.content}
                primaryAction={{
                  title: 'Shop Now',
                  onClick: () => handleNavigation('Blocks'),
                }}
                secondaryAction={{
                  title: 'Learn More',
                  href: 'https://streamvirtualassistant.com/',
                }}
                capsuleTitle="New"
                width="100%"
                minWidth="320px"
                type="success"
              />
            </Div>
          )} */}
          </Flex>
          {shouldColumnWrap && <PromptingsUpcomingEvents />}
        </Flex>
        <Flex
          flexDirection="column"
          minWidth="420px"
          columnGap="24px"
          rowGap="24px"
          flexGrow={1}
          width={shouldColumnWrap ? '100%' : '30%'}
          height="100%"
        >
          {!shouldColumnWrap && <RemindersListWidget />}
          {!shouldColumnWrap && <PromptingsUpcomingEvents />}
        </Flex>
      </Flex>
      {showExlusiveOffer && (
        <Portal>
          <div
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              zIndex: 510, // Needs to be higher than the sidebar
              backgroundColor: 'white',
            }}
          >
            <ExclusiveOfferScreen
              onClose={() => setHasClosedExclusiveOffer(true)}
            />
          </div>
        </Portal>
      )}
      {shouldDisplayJoinConfirmation && (
        <Portal>
          <div
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              zIndex: 510, // Needs to be higher than the sidebar
              backgroundColor: 'white',
            }}
          >
            <ExclusiveOfferConfirmationScreen
              onConfirm={() => {
                actions.setShouldDisplayJoinConfirmation(undefined)
                actions.setUpsellSlug('WILDCARD')
                actions.setPlanToDisplay('189')
                actions.setShouldShowUpsell(true)
              }}
              onClose={() => {
                actions.setShouldDisplayJoinConfirmation(undefined)
                actions.openAccount({ shouldDisplayDashboardSplash: '' })
              }}
            />
          </div>
        </Portal>
      )}
      {shouldShowSplashScreen && (
        <DashboardSplashScreen
          onClose={() => setShouldShowSplashScreen(false)}
        />
      )}
    </Flex>
  )
}

export default DashboardHomeView
