import { CheckoutFragment } from 'src/saleor_graphql/generated/graphql'

export const checkIsInCart = (
  checkout?: CheckoutFragment | null,
  sku?: string | null,
) => {
  if (!checkout) return false
  if (sku) {
    return checkout.lines.some(line => line.variant.sku === sku)
  }
  return false
}
