import React from 'react'
import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { SettingCard } from './components/SettingCard'
import { SplashHeader } from './components/SplashHeader'
import { SplashFooter } from './components/SplashFooter'
import { useAccount, useSelector, useState } from 'src/hooks'
import { useAccountSettings } from './hooks/useAccountSettings'
import { AddressView } from './views/Address/AddressView'
import { PaymentMethodView } from './views/PaymentMethod/PaymentMethodView'
import { AccountInfoSettingsView } from 'src/dashboard/containers/Views/Information/AccountInfoSettingsView'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { CurrentMiniUpsale } from 'src/act/components/CurrentMiniUpsale/CurrentMiniUpsale'
import { ExclusiveOffer } from './components/ExclusiveOffer'

export const accountSettingViews: Record<
  string,
  (onClose: () => void) => JSX.Element
> = {
  addressView: onClose => <AddressView onClose={onClose} />,
  paymentView: onClose => <PaymentMethodView onClose={onClose} />,
}

type DashboardSplashScreenProps = {
  onClose: () => void
}
export const DashboardSplashScreen = ({
  onClose,
}: DashboardSplashScreenProps) => {
  const [activeSettingView, setActiveSettingView] = useState<
    keyof typeof accountSettingViews | undefined
  >()
  const [shouldShowProfieSettings, setShouldShowProfileSettings] =
    useState<boolean>(false)
  const account = useAccount()
  const accountSettings = useAccountSettings(
    setActiveSettingView,
    setShouldShowProfileSettings,
  )
  const isMobile = useSelector(state => state.window.isMobile)

  const activeSubscriptionText = `Promptings ${account.plan.title}`

  return (
    <Flex flexDirection="column" alignItems="center" style={styles.container}>
      <SplashHeader />
      <Flex
        flexDirection="column"
        rowGap="16px"
        position="relative"
        top="88px"
        maxWidth="960px"
      >
        <Flex
          id="user_greeting"
          flexDirection="column"
          alignItems="flex-start"
          rowGap="8px"
          outset={{ bottom: 'x1_5' }}
        >
          <Text
            content={`Hello ${account.firstName}`}
            type="largeBody"
            color="primaryHeading"
            weight="bold"
          />
          <Flex
            justifyContent="center"
            alignItems="center"
            inset={{ vertical: '2px', horizontal: '5px' }}
            style={styles.subscriptionBox}
          >
            <Text
              content={activeSubscriptionText}
              type="footnote"
              weight="bold"
              style={{ lineHeight: 'unset' }}
            />
          </Flex>
        </Flex>
        <Flex maxWidth="500px" style={{ margin: '0 auto' }}>
          <CurrentMiniUpsale size="Xsmall" />
        </Flex>
        <Flex>
          <ExclusiveOffer />
        </Flex>
        {accountSettings.map(({ title, description, status, onClick }) => (
          <SettingCard
            key={title}
            title={title}
            description={description}
            status={status}
            // customStatusTitles={}
            onClick={onClick}
          />
        ))}

        <Spacer space="x2" />
        <SplashFooter onClose={onClose} />
      </Flex>

      {activeSettingView
        ? accountSettingViews[activeSettingView](() =>
            setActiveSettingView(undefined),
          )
        : null}
      <Dialog
        title="Edit Profile"
        padding={isMobile ? '72px 16px' : '12px 24px 12px 24px'}
        isOpen={shouldShowProfieSettings}
        onClose={() => setShouldShowProfileSettings(false)}
        mobileOpenHeight={'0px'}
      >
        <AccountInfoSettingsView shouldHideAddress />
      </Dialog>
    </Flex>
  )
}

const styles = {
  container: {
    backgroundColor: '#F6F6F6',
    height: '100dvh',
    width: '100dvw',
    padding: 24,
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'auto',
    paddingBottom: '200px',
    zIndex: 1000,
  } as const,
  subscriptionBox: {
    borderRadius: 4,
    border: '1px solid rgba(0, 0, 0, 0.16)',
    backgroundColor: 'foreground',
    rowGap: '10px',
  },
}
