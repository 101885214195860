import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

export type OptionType = {
  title: string
  price: string
  isMonthly: boolean
  optionId: string
  sku?: string
  disabledReason?: 'current plan' | 'added to cart'
}

export type SelectableUpsaleOptionsType = {
  options: OptionType[]
  selectedOption?: string
  onSelectOption: (optionId: string, sku?: string) => void
  customGradient?: string[]
}

export const SelectableUpsaleOptions: React.FC<SelectableUpsaleOptionsType> = ({
  options,
  selectedOption,
  onSelectOption,
  customGradient,
}) => {
  const fontFamily = 'Montserrat, sans-serif'
  return (
    <Flex flexDirection="column" rowGap="x1" outset={{ vertical: 'x2' }}>
      {options.map(option => {
        const isSelected = option.optionId === selectedOption
        return option.disabledReason ? (
          <Flex
            key={option.title}
            width="290px"
            height="46px"
            cursor="pointer"
            alignItems="center"
            borderRadius="12px"
            justifyContent="space-between"
            backgroundColor={isSelected ? '#fff' : '#ffffff38'}
            style={{
              border: '2px solid rgba(236, 236, 236, 0.40)',
              padding: '8px 12px',
              transition: '.3s ease-in-out',
            }}
          >
            <Text
              type="body"
              style={{
                fontSize: '14px',
                color: isSelected ? '#151515' : '#5A5A5A',
                fontWeight: 500,
                fontFamily: fontFamily,
                transition: '.3s ease-in-out',
              }}
              content={`${
                option.disabledReason === 'current plan'
                  ? 'Current Plan'
                  : option.disabledReason === 'added to cart'
                    ? 'Added to Cart'
                    : 'disabled'
              }`}
            />
            <Text
              type="body"
              style={{
                fontSize: '16px',
                color: isSelected ? '#303030' : '#5A5A5A',
                fontWeight: 600,
                fontFamily: fontFamily,
                transition: '.3s ease-in-out',
              }}
              content={option.price}
            />
          </Flex>
        ) : (
          <Flex
            key={option.title}
            justifyContent="space-between"
            cursor="pointer"
            width="290px"
            height="46px"
            alignItems="center"
            borderRadius="12px"
            style={{
              border: '2px solid rgba(236, 236, 236, 0.40)',
              padding: '8px 12px',
              transition: '.3s ease-in-out',
            }}
            onClick={() => onSelectOption(option.optionId, option.sku)}
            backgroundColor={isSelected ? '#fff' : '#ffffff38'}
          >
            <Flex alignItems="center">
              <Flex
                alignItems="center"
                justifyContent="center"
                width="16px"
                height="16px"
                backgroundColor={isSelected ? '#f3f3f3' : '#fff'}
                borderRadius="50%"
                style={{
                  border: !isSelected ? '2px solid #F0F0F0' : undefined,
                  animation: 'pulse 0.6s infinite ease-in-out',
                }}
              >
                {isSelected && (
                  <Icon
                    size={10}
                    color={customGradient ? customGradient[0] : '#005AC4'}
                    name={'check'}
                  />
                )}
              </Flex>
              <Text
                whiteSpace="nowrap"
                outset={{ left: 'x1' }}
                type="body"
                lineHeight={1}
                style={{
                  fontSize: '14px',
                  color: isSelected ? '#151515' : '#5A5A5A',
                  fontWeight: 500,
                  fontFamily: fontFamily,
                  transition: '.3s ease-in-out',
                }}
              >
                {option.title}
              </Text>
            </Flex>
            <Flex width="100%" justifyContent="end">
              <Text
                outset={{ left: 'x1' }}
                type="body"
                weight="bold"
                lineHeight={1}
                style={{
                  fontSize: '16px',
                  color: isSelected ? '#303030' : '#5A5A5A',
                  fontWeight: 600,
                  fontFamily: fontFamily,
                  transition: '.3s ease-in-out',
                }}
              >
                {option.price.slice(0, -2)}
              </Text>
              <Text
                style={{ color: '#333', fontSize: '10px' }}
                weight="bold"
                lineHeight={1}
                type={'footnote'}
              >
                .00
              </Text>
              {option.title !== 'One-time Purchase' && (
                <Text
                  lineHeight={1}
                  type={'footnote'}
                  style={{
                    color: '#ACACAC',
                    fontSize: '8px',
                    fontWeight: 500,
                  }}
                >
                  /mo
                </Text>
              )}
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}
