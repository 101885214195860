import React from 'react'
import { Flex, Div, Text } from '@sendoutcards/quantum-design-ui'

export type StatusType = 'missing' | 'incomplete' | 'complete' | 'custom'

type StatusPillProps = {
  status: StatusType
  customStatusTitle?: string
}

export const StatusPill = ({
  status,
  customStatusTitle = 'Custom Title',
}: StatusPillProps) => {
  const statusRules: Record<
    StatusType,
    { background: string; color: string; title: string }
  > = {
    missing: {
      background: '#E7515D14',
      color: '#E7515D',
      title: 'Missing',
    },
    incomplete: {
      background: '#D9770614',
      color: '#D97706',
      title: 'Incomplete',
    },
    complete: {
      background: '#20966F1A',
      color: '#20966F',
      title: 'Complete',
    },
    custom: {
      background: '#60A5FA14',
      color: '#60A5FA',
      title: customStatusTitle,
    },
  }

  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      style={{
        backgroundColor: statusRules[status].background,
      }}
      inset={{ vertical: '2px', horizontal: '14px' }}
      borderRadius="medium"
    >
      <Div
        borderRadius="circle"
        style={{
          width: '6px',
          height: '6px',
          backgroundColor: statusRules[status].color,
          marginRight: 4,
        }}
      />
      <Text
        content={statusRules[status].title}
        type="footnote"
        weight="bold"
        style={{ color: statusRules[status].color }}
      />
    </Flex>
  )
}
