import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

export type UpsaleSizeType = 'Large' | 'Medium' | 'Small' | 'Xsmall'

export type UpsaleType = 'Ocean' | 'Rose'

export type ImageProps = {
  imgUrl: string
  width?: string
  height?: string
}

type ActionType = {
  title: string
  onClick: () => void
}

export type MiniUpsalesProps = {
  size: UpsaleSizeType
  type: UpsaleType
  image: ImageProps
  title: string
  shouldReverseOrder?: boolean
  shouldReverseAction?: boolean
  shouldStackAction?: boolean
  primaryAction: ActionType
}

export const MiniUpsales = (props: MiniUpsalesProps) => {
  const {
    size,
    type,
    image,
    title,
    primaryAction,
    shouldReverseOrder,
    shouldReverseAction,
    shouldStackAction,
  } = props

  const fontFamily = 'Montserrat, sans-serif'
  const isMinimizeDisplay = size === 'Small' || size === 'Xsmall'

  const getUpsaleSize = (size: UpsaleSizeType) =>
    ({
      Large: {
        width: '300px',
        height: '250px',
        titleFontSize: '16px',
        btnFontSize: '14px',
        defaultImageSize: '120px',
      },
      Medium: {
        width: '200px',
        height: '200px',
        titleFontSize: '13px',
        btnFontSize: '10px',
        defaultImageSize: '100px',
      },
      Small: {
        width: '100%',
        height: '140px',
        titleFontSize: '14px',
        btnFontSize: '10px',
        defaultImageSize: '110px',
      },
      Xsmall: {
        width: '100%',
        height: '50px',
        titleFontSize: '11px',
        btnFontSize: '8px',
        defaultImageSize: '60px',
      },
    })[size]

  const { width, height, titleFontSize, btnFontSize, defaultImageSize } =
    getUpsaleSize(size)

  const background =
    type === 'Rose'
      ? 'linear-gradient(313deg, #E87592 13.66%, #98A3FD 84.85%), linear-gradient(114deg, #75E8DB 25.57%, #98A3FD 75.94%)'
      : 'linear-gradient(90deg, #277AD4 -14.79%, #4AB5D9 51.69%, #67E5DD 105.63%), #677789'

  const btnBackground =
    type === 'Rose' ? 'rgba(68, 68, 68, 0.30)' : 'rgba(255, 255, 255, 0.30)'

  return (
    <Div
      overflow="hidden"
      display="flex"
      inset={isMinimizeDisplay ? 'x2' : 'x_5'}
      width={width}
      height={height}
      justifyContent={isMinimizeDisplay ? 'flex-start' : 'center'}
      alignItems="center"
      flexDirection={isMinimizeDisplay ? 'row' : 'column'}
      style={{
        background: background,
        borderRadius: '16px',
        boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
      }}
    >
      <Flex order={shouldReverseOrder ? 2 : 1}>
        <Div
          style={{
            width: image.width ?? defaultImageSize,
            height: image.height ?? defaultImageSize,
            backgroundImage: `url(${image.imgUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        />
      </Flex>
      <Flex
        order={shouldReverseOrder ? 1 : 2}
        flexDirection={
          !shouldStackAction && isMinimizeDisplay ? 'row' : 'column'
        }
        justifyContent={'center'}
        alignItems={
          shouldStackAction && isMinimizeDisplay ? 'flex-start' : 'center'
        }
        rowGap="x_5"
        columnGap="x_5"
      >
        <Flex order={shouldReverseAction ? 2 : 1}>
          <Text
            type="body"
            content={title}
            lineHeight={1.4}
            alignment={isMinimizeDisplay ? undefined : 'center'}
            style={{
              color: '#fff',
              fontFamily: fontFamily,
              fontSize: titleFontSize,
              fontWeight: 700,
              textShadow: '0px 1px 0px rgba(0, 0, 0, 0.08)',
            }}
          />
        </Flex>
        <Flex order={shouldReverseAction ? 1 : 2}>
          <div
            onClick={primaryAction.onClick}
            style={{
              borderRadius: '16px',
              boxShadow: '0px 0px 32px 0px rgba(0, 0, 0, 0.12)',
              backgroundColor: btnBackground,
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              fontSize: btnFontSize,
              fontFamily: fontFamily,
              textShadow: '0px 1px 0px rgba(0, 0, 0, 0.08)',
              fontWeight: 700,
              width: 'fit-content',
              height: 'fit-content',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            {primaryAction.title}
          </div>
        </Flex>
      </Flex>
    </Div>
  )
}
