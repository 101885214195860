import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useActions } from 'src/hooks'

type SplashFooterProps = {
  onClose: () => void
}
export const SplashFooter = ({ onClose }: SplashFooterProps) => {
  const actions = useActions()
  return (
    <Flex
      id="after_actions_footer"
      width="100%"
      justifyContent={'space-between'}
    >
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        columnGap={'x1'}
        cursor="pointer"
        onClick={onClose}
      >
        <Icon name="home" width={18} height={18} />
        <Text type="footnote" content="Go to Dashboard" />
      </Flex>

      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        columnGap={'x1'}
        onClick={actions.logout}
        cursor="pointer"
      >
        <Icon name="logout" size={18} />
        <Text type="footnote" content="Logout" color="danger" />
      </Flex>
    </Flex>
  )
}
