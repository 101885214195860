import React, { FC } from 'react'
import { SVGProps } from './types'

export const Arrow: FC<SVGProps> = ({
  width = 16,
  height = 10,
  fill = '#303030',
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 10"
      fill="none"
      onClick={onClick}
    >
      <path
        d="M1 5H15"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 5L5 9"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 5L5 1"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
